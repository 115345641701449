import React from 'react';
import MobileOrientationImage from '../../assets/images/mobile-orientation.svg';
import Styles from './mobileorientation.module.scss';

const MobileOrientation = () => {
  return (
    <div className={Styles.OrientationContainer}>
      <img src={MobileOrientationImage} />
      <h4>Please switch to Portrait View</h4>
    </div>
  );
};

export default MobileOrientation;
