import React from 'react';
import Styles from './tabs.module.scss';
import { useIsMobile } from '../../utils/customHooks';

const Tabs = ({
  thumbSlide,
  activeTab,
  onClick,
  collectionLabel,
  editorialLabel,
  toggle,
  toggleM,
}) => {
  const isM = useIsMobile();
  return (
    <>
      {
        <div className={Styles.tabs}>
          <button
            className={`${Styles.btnLookBook} ${
              activeTab === 'lookBook' ? Styles.active : ''
            }`}
            onClick={() => {
              toggle('lookBook');
            }}
          >
            <span>{collectionLabel}</span>
          </button>
          <button
            className={`${Styles.btnEditorial} ${
              activeTab === 'editorial' ? Styles.active : ''
            }`}
            onClick={() => {
              toggle('editorial');
            }}
          >
            <span>{editorialLabel}</span>
          </button>
        </div>
      }
      {/* {isM && (
        <div className={Styles.tabs}>
          <button
            className={`${Styles.btnLookBook} ${
              activeTab === 'lookBook' ? Styles.active : ''
            }`}
            onClick={() => {
              toggleM('lookBook');
            }}
          >
            <span>{collectionLabel}</span>
          </button>
          <button
            className={`${Styles.btnEditorial} ${
              activeTab === 'editorial' ? Styles.active : ''
            }`}
            onClick={() => {
              toggleM('editorial');
            }}
          >
            <span>{editorialLabel}</span>
          </button>
        </div>
      )} */}
    </>
  );
};

export default Tabs;
