import React, { useRef, useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
} from 'swiper/core';
import { AiOutlineClose } from 'react-icons/ai';
import { useIsMobile } from '../../utils/customHooks';

import Styles from './lightbox.module.scss';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import Tags from '../Tags';
import OutsideAlerter from '../OutsideClick';
SwiperCore.use([EffectFade, Navigation, Pagination, Mousewheel]);

const LightBox = ({
  onCloseRequest,
  collectionImages = [],
  mainSrc,
  photoIndex,
  activeTab,
}) => {
  const lightBoxSwiperRef = useRef(null);
  const [slideIndex, setSlideIndex] = useState(0);

  const isM = useIsMobile();

  useEffect(() => {
    if (slideIndex != null) {
      lightBoxSwiperRef.current.swiper.slideTo(slideIndex, 0);
    }
    return () => {};
  }, [slideIndex]);

  const onSwiperInit = () => {
    setSlideIndex(photoIndex);
  };
  const swiperProps = {
    className: Styles.swiperContainer,
    spaceBetween: 10,
    loop: false,
    navigation: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slidesPerView: '1',
    mousewheel: {
      sensitivity: 2,
    },
    hashNavigation: {
      watchState: true,
    },
    pagination: {
      dynamicBullets: false,
      el: '.custom-pagination-lightbox',
      clickable: true,
      renderBullet: (index, className) => {
        return `<span class="${className} custom-pagination-bullet" ></span>`;
      },
    },
  };
  return (
    <div
      data-value="lightbox"
      className={`${Styles.lightBoxContainer}`}
      onClick={onCloseRequest}
    >
      {!isM && (
        <Swiper
          data-value="swiper"
          {...swiperProps}
          ref={lightBoxSwiperRef}
          onInit={() => onSwiperInit()}
          simulateTouch={false}
        >
          {collectionImages.length > 0 &&
            collectionImages.map(
              ({ id, url, caption, alt, tags }, idx) => (
                <SwiperSlide
                  className={`${Styles.slides} ${Styles.deliveriesSlides}`}
                  key={`${id}`}
                  data-value="slides"
                >
                  <figure>
                    <img src={url} alt={alt} className="img-fluid" />
                    {activeTab === 'lookBook' && (
                      <div>
                        <Tags tags={tags} />
                      </div>
                    )}

                    <div className={Styles.closeBtn}>
                      <p>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 1024 1024"
                          data-value="closeButton"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={onCloseRequest}
                        >
                          <path
                            onClick={onCloseRequest}
                            data-value="closeButton"
                            d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                          ></path>
                        </svg>
                      </p>
                      <span data-value="closeLabel">CHIUDI</span>
                    </div>
                  </figure>
                </SwiperSlide>
              ),
            )}
        </Swiper>
      )}
      {isM && (
        <Swiper
          data-value="swiper"
          {...swiperProps}
          ref={lightBoxSwiperRef}
          onInit={() => onSwiperInit()}
          simulateTouch={true}
        >
          {collectionImages.length > 0 &&
            collectionImages.map(
              ({ id, url, caption, alt, tags }, idx) => (
                <SwiperSlide
                  className={`${Styles.slides} ${Styles.deliveriesSlides}`}
                  key={`${id}`}
                  data-value="slides"
                >
                  <figure>
                    <img src={url} alt={alt} className="img-fluid" />
                    {activeTab === 'lookBook' && (
                      <div>
                        <Tags tags={tags} />
                      </div>
                    )}

                    <div className={Styles.closeBtn}>
                      <p>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 1024 1024"
                          data-value="closeButton"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={onCloseRequest}
                        >
                          <path
                            onClick={onCloseRequest}
                            data-value="closeButton"
                            d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"
                          ></path>
                        </svg>
                      </p>
                      <span data-value="closeLabel">CHIUDI</span>
                    </div>
                  </figure>
                </SwiperSlide>
              ),
            )}
        </Swiper>
      )}

      <div className={Styles.paginationContainer}>
        <div className="custom-pagination-lightbox"></div>
      </div>
    </div>
  );
};

export default LightBox;
