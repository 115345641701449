import React, { useState } from 'react';
import '../i18n';
import { useIsLandscape } from '../utils/customHooks';
import MobileOrientation from './MobileOrientation';
import Navbar from './Navbar';

const App = ({ children }) => {
  const isLandscape = useIsLandscape();
  return (
    <>
      <div className="ax-container">
        <Navbar />
        {isLandscape && <MobileOrientation />}
        {!isLandscape && (
          <div className={`page-body`}>{children}</div>
        )}
      </div>
    </>
  );
};

export default App;
