import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Styles from './tags.module.scss';
import Button from '../Button';
import { FaShoppingBag } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
const Tags = ({ tags }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  return (
    <div>
      {show
        ? tags.map((tag) => {
            if ('url' in tag) {
              return (
                <a href={tag.url} key={tag.id} target="_blank">
                  <div
                    id="tags"
                    className={Styles.tag}
                    style={{ top: tag.top, left: tag.left }}
                  >
                    <FaShoppingBag className="d-md-none" />
                    <p className={Styles.productName}>
                      {t(tag.product_name)}
                    </p>
                    <p className={Styles.shopTagText}>
                      <span>{t(tag.shop_online)}</span>
                    </p>
                  </div>
                </a>
              );
            } else {
              return '';
            }
          })
        : null}
      {tags.map((tag) => {
        if ('url' in tag) {
          return (
            <figcaption key={tag.id}>
              <Button
                className={`btn ${Styles.btnCaption}`}
                onClick={() => setShow(!show)}
              >
                {show ? <FaEyeSlash /> : <FaEye />}
                <span>
                  {show
                    ? t('tags.button-hide')
                    : t('tags.button-show')}
                </span>
              </Button>
            </figcaption>
          );
        }
      })}
    </div>
  );
};

export default Tags;
