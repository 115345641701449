import React, { useRef, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import Styles from './thumbSliderMobile.module.scss';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
import { Link } from 'react-router-dom';

const ThumbSliderMobile = ({
  menus,
  setSlide,
  currentSlide,
  prevRef,
  nextRef,
  activeTab,
  slideRoute,
  slideChange,
  setThumbSlideMenu,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    menus.map(
      ({ param, slide_lb_first_index_m, slide_ed_first_index_m }) => {
        if (slideRoute === param) {
          setSlide(
            activeTab === 'lookBook'
              ? slide_lb_first_index_m
              : slide_ed_first_index_m,
          );
        }
      },
    );

    return () => {};
  }, [slideRoute]);

  const swiperRef = useRef(null);

  return (
    <div className={Styles.thumbSlideContainer}>
      <Swiper
        loop={false}
        slidesPerView={'auto'}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        className="mySwiper"
        spaceBetween={30}
        ref={swiperRef}
      >
        {menus
          .filter((menu) => {
            return (
              activeTab === 'editorial' || activeTab === 'lookBook'
            );
          })
          .map(
            (
              {
                id,
                title,
                background_image,
                slide_lb_first_index_m,
                slide_ed_first_index_m,
                url,
                param,
              },
              idx,
            ) => (
              <SwiperSlide
                className={Styles.swiperSlideCustom}
                key={idx}
                id={`tumbSlideIndex${idx}`}
                onClick={() => {
                  setSlide(
                    activeTab === 'lookBook'
                      ? slide_lb_first_index_m
                      : slide_ed_first_index_m,
                  );
                  if (idx == 0) {
                    document
                      .getElementById('mainSlide0')
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide0')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide1')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide2')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide3')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide4')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide5')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide6')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide7')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  } else if (idx == 1) {
                    document
                      .getElementById(`mainSlide1`)
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide1')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide0')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide2')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide3')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide4')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide5')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide6')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide7')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  } else if (idx == 2) {
                    document
                      .getElementById(`mainSlide2`)
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide2')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide0')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide1')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide3')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide4')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide5')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide6')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide7')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  } else if (idx == 3) {
                    document
                      .getElementById(`mainSlide3`)
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide3')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide0')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide1')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide2')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide4')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide5')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide6')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide7')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  } else if (idx == 4) {
                    document
                      .getElementById(`mainSlide4`)
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide4')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide0')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide1')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide2')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide3')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide5')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide6')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide7')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  } else if (idx == 5) {
                    document
                      .getElementById(`mainSlide5`)
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide5')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide0')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide1')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide2')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide3')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide4')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide6')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide7')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  } else if (idx == 6) {
                    document
                      .getElementById(`mainSlide6`)
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide6')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide0')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide1')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide2')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide3')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide4')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide5')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide7')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  } else if (idx == 7) {
                    document
                      .getElementById(`mainSlide7`)
                      .scrollIntoView();
                    document
                      .getElementById('tumbSlide7')
                      .classList.add(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide0')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide1')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide2')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide3')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide4')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide5')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                    document
                      .getElementById('tumbSlide6')
                      .classList.remove(
                        'thumbSliderMobile_active__1n7h4',
                      );
                  }
                }}
              >
                <Link
                  to={`${url}/${param}`}
                  className={Styles.slideNav}
                >
                  <div
                    id={`tumbSlide${idx}`}
                    className={`${Styles.thumbSlide} ${
                      currentSlide ===
                      (activeTab === 'lookBook'
                        ? slide_lb_first_index_m
                        : slide_ed_first_index_m)
                        ? Styles.active
                        : ''
                    }`}
                    style={{
                      backgroundImage:
                        'url(' + background_image + ')',
                    }}
                  ></div>
                  <div className={Styles.thumbSlideTitle}>
                    {t(title)}
                  </div>
                </Link>
              </SwiperSlide>
            ),
          )}
      </Swiper>
    </div>
  );
};

export default ThumbSliderMobile;
