import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/app.scss';
import App from './components/App';

import { use100vh } from 'react-div-100vh';
import { Switch, Route, Redirect } from 'react-router-dom';
import { routes } from './routes';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import { catalogueData } from './context/data';
import { DataProvider } from './context';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-N52VVR5',
};

TagManager.initialize(tagManagerArgs);

const Root = () => {
  const height = use100vh();
  const viewHeight = height ? height : '100vh';
  const viewHeightMobile = height ? height / 1.149 : '87vh';
  document.documentElement.style.setProperty(
    '--vh',
    `${viewHeight}px`,
  );
  document.documentElement.style.setProperty(
    '--vhm',
    `${viewHeightMobile}px`,
  );

  const retrieveParams = () => {
    let storedParams;
    if ('URLSearchParams' in window) {
      // Browser supports URLSearchParams
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const requestedParams = [
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'gclid',
      ];
      const hasRequestedParams = requestedParams.some((param) => {
        // true if it exists
        return !!params.get(param);
      });
      if (hasRequestedParams) {
        storedParams = params;
      }
    }
    return storedParams;
  };

  const storedParams = window.storedParams || retrieveParams();
  let storedParamsUrl;
  if (storedParams) {
    // update window value
    window.storedParams = storedParams;
    // create the url
    const urlWithoutParams =
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname;
    storedParamsUrl = `${urlWithoutParams}?${storedParams}`;
  }

  const nodeRef = useRef(null);

  return (
    <React.StrictMode>
      <Router basename={`/`}>
        <Switch>
          <DataProvider value={catalogueData}>
            <App>
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                render={() => {
                  return (
                    <Redirect
                      to={`${process.env.PUBLIC_URL}/${document.location.search}`}
                    />
                  );
                }}
              />

              <TransitionGroup>
                {routes.map(({ path, Component }) => (
                  <Route
                    key={path}
                    exact
                    path={`${process.env.PUBLIC_URL}${path}`}
                  >
                    {({ match, history }) => (
                      <CSSTransition
                        in={match != null}
                        timeout={200}
                        classNames={'fade'}
                        unmountOnExit
                      >
                        <div ref={nodeRef}>
                          <Component
                            params={match?.params}
                            history={history}
                          />
                        </div>
                      </CSSTransition>
                    )}
                  </Route>
                ))}
              </TransitionGroup>
            </App>
          </DataProvider>
        </Switch>
      </Router>
    </React.StrictMode>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
