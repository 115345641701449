import React, { useState } from 'react';
import Styles from './navbar.module.scss';
import logo from '../../assets/images/ax-logo.svg';
import Language from '../Language';
import Languages from '../../pages/HomePage/index';
import Menu from '../Menu';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useIsMobile } from '../../utils/customHooks';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Navbar = () => {
  const { t, i18n } = useTranslation();
  // moment.locale(i18n.languages[1] || 'it');
  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem('storedLanguage') ||
      i18n.language.split('-')[0],
  );

  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState(false);
  const isM = useIsMobile();
  const toggleMenu = () => {
    setSelectedMenu(!selectedMenu);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src={logo} alt="" width="75" />
          </a>
          {/* for now deleted campaign fw21-22 */}
          {/* {!isM && location.pathname.includes('/collections') && (
            <h3 className={`fw-bold ${Styles.navbarPageTitle}`}>
              CAMPAIGN FW21-22
            </h3>
          )} */}
          <div className="d-flex align-item-center">
            <Language
              language={currentLanguage}
              setLanguage={setCurrentLanguage}
            />
            <button
              onClick={toggleMenu}
              className={`btn ${Styles.menuToggler}`}
            >
              <span
                className={`${Styles.iconBar} ${Styles.topBar}`}
              ></span>
              <span
                className={`${Styles.iconBar} ${Styles.middleBar}`}
              ></span>
              <span
                className={`${Styles.iconBar} ${Styles.bottomBar}`}
              ></span>
            </button>
          </div>
        </div>
      </nav>
      <CSSTransition
        in={selectedMenu}
        timeout={200}
        classNames="fade"
        unmountOnExit
      >
        <Menu
          toggleMenu={toggleMenu}
          language={currentLanguage}
          setLanguage={setCurrentLanguage}
        />
      </CSSTransition>
    </>
  );
};

export default Navbar;
