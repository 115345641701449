import React, { useContext, useEffect } from 'react';
import Styles from '../HomePage/home.module.scss';
import { Link } from 'react-router-dom';
import DataContext from '../../context';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Button';
import Marquee from 'react-marquee-slider';
import times from 'lodash/times';
import { useIsMobile } from '../../utils/customHooks';

const Home = () => {
  const { t } = useTranslation();
  const { home } = useContext(DataContext);
  const isM = useIsMobile();

  return (
    <div className={Styles.homepage}>
      <video
        autoPlay
        loop
        muted
        id="homepage-video"
        className={Styles.homepageVideo}
        webkit-playsinline="true"
        playsInline={true}
      >
        <source src={home?.video.url} type={home?.video.type} />
      </video>
      <video
        autoPlay
        loop
        muted
        id="homepage-video"
        className={Styles.homepageVideo}
        webkit-playsinline="true"
        playsInline={true}
      >
        <source src={home?.video.url} type={home?.video.type} />
      </video>
      <div className={Styles.overlay}>
        <div className={Styles.content}>
          <div className={Styles.contentWrap}>
            <h1>A|X ARMANI EXCHANGE</h1>
            <h1>{t(home.title)}</h1>
            <Link
              to="/collections/anniversary"
              className={Styles.button}
            >
              <Button>{t('button')}</Button>
            </Link>
          </div>
        </div>
      </div>
      {/* do not delete it */}
      {/* <div className={Styles.newArrivalsBanner}>
        <div className={Styles.newArrivalsText}>
          <Marquee velocity={isM ? 10 : 30}>
            {times(10, Number).map(() => (
              <div className={Styles.exploreNewArrivals}>
                <Link
                  to="/collections/neverTooLoud"
                  className={Styles.exploreNewArrivalsLink}
                >
                  {t(home.Explore_our_latest_arrivals)}
                </Link>{' '}
                <Link
                  to="/collections/neverTooLoud"
                  className={Styles.exploreNewArrivalsLinkBold}
                >
                  {t(home.Explore_our_latest_arrivals)}
                </Link>
              </div>
            ))}
          </Marquee>
        </div>
      </div> */}
    </div>
  );
};

export default Home;
