import React from 'react';
import Styles from './titleslide.module.scss';
import { FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const TitleSlide = ({
  navs,
  title,
  title1,
  description,
  have_nav,
  nav_title,
}) => {
  const { t } = useTranslation();
  return (
    <div className={Styles.titleTextDetails}>
      <h1 className="fw-bold">{title}</h1>
      <h1 className="fw-bold">{title1}</h1>
      <p>{description}</p>
      {have_nav && (
        <div>
          <p className="text-start">{t(nav_title)}</p>
          <div className={Styles.deliveryNavs}>
            {navs.map(({ title, url }, idx) => (
              <a key={`${idx}`} href={url} target="_blank">
                <span>{t(title)}</span> <FaChevronRight />
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TitleSlide;
