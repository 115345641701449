// Lookbook Images
import everything_from_a_to_x_1 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/1.jpg';
import everything_from_a_to_x_2 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/2.jpg';
import everything_from_a_to_x_3 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/3.jpg';
import everything_from_a_to_x_4 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/4.jpg';
import everything_from_a_to_x_5 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/5.jpg';
import everything_from_a_to_x_6 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/6.jpg';
import everything_from_a_to_x_7 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/7.jpg';
import everything_from_a_to_x_8 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/8.jpg';
import everything_from_a_to_x_9 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/9.jpg';

import primavera_sound_1 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/1.jpg';
import primavera_sound_2 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/2.jpg';
import primavera_sound_3 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/3.jpg';
import primavera_sound_4 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/4.jpg';
import primavera_sound_5 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/5.jpg';
import primavera_sound_6 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/6.jpg';
import primavera_sound_7 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/7.jpg';
import primavera_sound_8 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/8.jpg';
import primavera_sound_9 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/9.jpg';

import desert_festival_1 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/1.jpg';
import desert_festival_2 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/2.jpg';
import desert_festival_3 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/3.jpg';
import desert_festival_4 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/4.jpg';
import desert_festival_5 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/5.jpg';
import desert_festival_6 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/6.jpg';
import desert_festival_7 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/7.jpg';
import desert_festival_8 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/8.jpg';
import desert_festival_9 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/9.jpg';
import desert_festival_10 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/10.jpg';
import desert_festival_11 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/11.jpg';
import desert_festival_12 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/12.jpg';
import desert_festival_13 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/13.jpg';
import desert_festival_14 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/14.jpg';

import everything_from from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/9.jpg';

import ax_beats_1 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/1.jpg';
import ax_beats_2 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/2.jpg';
import ax_beats_3 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/3.jpg';
import ax_beats_4 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/4.jpg';
import ax_beats_5 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/5.jpg';
import ax_beats_6 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/6.jpg';
import ax_beats_7 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/7.jpg';
import ax_beats_8 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/8.jpg';
import ax_beats_9 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/9.jpg';
import ax_beats_10 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/10.jpg';
import ax_beats_11 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/11.jpg';
import ax_beats_12 from '../assets/images/catalogue-2022/AX-Beats/COLLEZIONE/12.jpg';

import denim_love_1 from '../assets/images/catalogue-2022/DENIM-LOVE/COLLEZIONE/1.jpg';
import denim_love_2 from '../assets/images/catalogue-2022/DENIM-LOVE/COLLEZIONE/2.jpg';
import denim_love_3 from '../assets/images/catalogue-2022/DENIM-LOVE/COLLEZIONE/3.jpg';
import denim_love_4 from '../assets/images/catalogue-2022/DENIM-LOVE/COLLEZIONE/4.jpg';
import denim_love_5 from '../assets/images/catalogue-2022/DENIM-LOVE/COLLEZIONE/5.jpg';
import denim_love_6 from '../assets/images/catalogue-2022/DENIM-LOVE/COLLEZIONE/6.jpg';
import denim_love_7 from '../assets/images/catalogue-2022/DENIM-LOVE/COLLEZIONE/7.jpg';

import sunset_chill_1 from '../assets/images/catalogue-2022/SUNSET-CHILL/COLLEZIONE/1.jpg';
import sunset_chill_2 from '../assets/images/catalogue-2022/SUNSET-CHILL/COLLEZIONE/2.jpg';
import sunset_chill_3 from '../assets/images/catalogue-2022/SUNSET-CHILL/COLLEZIONE/3.jpg';
import sunset_chill_4 from '../assets/images/catalogue-2022/SUNSET-CHILL/COLLEZIONE/4.jpg';
import sunset_chill_5 from '../assets/images/catalogue-2022/SUNSET-CHILL/COLLEZIONE/5.jpg';

import block_party_1 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/1.jpg';
import block_party_2 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/2.jpg';
import block_party_3 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/3.jpg';
import block_party_4 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/4.jpg';
import block_party_5 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/5.jpg';
import block_party_6 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/6.jpg';
import block_party_7 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/7.jpg';
import block_party_8 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/8.jpg';
import block_party_9 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/9.jpg';
import block_party_10 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/10.jpg';
import block_party_11 from '../assets/images/catalogue-2022/BLOCK-PARTY/COLLEZIONE/11.jpg';

import tropical_house_1 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/1.jpg';
import tropical_house_2 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/2.jpg';
import tropical_house_3 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/3.jpg';
import tropical_house_4 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/4.jpg';
import tropical_house_5 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/5.jpg';
import tropical_house_6 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/6.jpg';
import tropical_house_7 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/7.jpg';
import tropical_house_8 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/8.jpg';
import tropical_house_9 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/9.jpg';
import tropical_house_10 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/COLLEZIONE/10.jpg';

// Editorial images

import everything_from_a_to_x_editorials_1 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/CAMPAGNA ADV/1.jpg';
import everything_from_a_to_x_editorials_2 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/CAMPAGNA ADV/2.jpg';
import everything_from_a_to_x_editorials_3 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/CAMPAGNA ADV/3.jpg';
import everything_from_a_to_x_editorials_4 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/CAMPAGNA ADV/4.jpg';
import everything_from_a_to_x_editorials_5 from '../assets/images/catalogue-2022/EVERYTHING-FROM-A-TO-X/CAMPAGNA ADV/5.jpg';

import primavera_sound_editorials_1 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/CAMPAGNA ADV/1.jpg';
import primavera_sound_editorials_2 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/CAMPAGNA ADV/2.jpg';
import primavera_sound_editorials_3 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/CAMPAGNA ADV/3.jpg';
import primavera_sound_editorials_4 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/CAMPAGNA ADV/4.jpg';
import primavera_sound_editorials_5 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/CAMPAGNA ADV/5.jpg';
import primavera_sound_editorials_6 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/CAMPAGNA ADV/6.jpg';
import primavera_sound_editorials_7 from '../assets/images/catalogue-2022/PRIMAVERA-SOUND/CAMPAGNA ADV/7.jpg';

import desert_festival_editorials_1 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/CAMPAGNA ADV/1.jpg';
import desert_festival_editorials_2 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/CAMPAGNA ADV/2.jpg';
import desert_festival_editorials_3 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/CAMPAGNA ADV/3.jpg';
import desert_festival_editorials_4 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/CAMPAGNA ADV/4.jpg';
import desert_festival_editorials_5 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/CAMPAGNA ADV/5.jpg';
import desert_festival_editorials_6 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/CAMPAGNA ADV/6.jpg';
import desert_festival_editorials_7 from '../assets/images/catalogue-2022/DESERT-FESTIVAL/CAMPAGNA ADV/7.jpg';

import ax_beats_editorials_1 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/1.jpg';
import ax_beats_editorials_2 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/2.jpg';
import ax_beats_editorials_3 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/3.jpg';
import ax_beats_editorials_4 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/4.jpg';
import ax_beats_editorials_5 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/5.jpg';
import ax_beats_editorials_6 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/6.jpg';
import ax_beats_editorials_7 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/7.jpg';
import ax_beats_editorials_8 from '../assets/images/catalogue-2022/AX-Beats/CAMPAGNA-ADV/8.jpg';

import denim_love_editorials_1 from '../assets/images/catalogue-2022/DENIM-LOVE/CAMPAGNA-ADV/1.jpg';
import denim_love_editorials_2 from '../assets/images/catalogue-2022/DENIM-LOVE/CAMPAGNA-ADV/2.jpg';
import denim_love_editorials_3 from '../assets/images/catalogue-2022/DENIM-LOVE/CAMPAGNA-ADV/3.jpg';
import denim_love_editorials_4 from '../assets/images/catalogue-2022/DENIM-LOVE/CAMPAGNA-ADV/4.jpg';

import sunset_chill_editorials_1 from '../assets/images/catalogue-2022/SUNSET-CHILL/CAMPAGNA-ADV/1.jpg';
import sunset_chill_editorials_2 from '../assets/images/catalogue-2022/SUNSET-CHILL/CAMPAGNA-ADV/2.jpg';
import sunset_chill_editorials_3 from '../assets/images/catalogue-2022/SUNSET-CHILL/CAMPAGNA-ADV/3.jpg';
import sunset_chill_editorials_4 from '../assets/images/catalogue-2022/SUNSET-CHILL/CAMPAGNA-ADV/4.jpg';
import sunset_chill_editorials_5 from '../assets/images/catalogue-2022/SUNSET-CHILL/CAMPAGNA-ADV/5.jpg';

import block_party_editorials_1 from '../assets/images/catalogue-2022/BLOCK-PARTY/CAMPAGNA-ADV/1.jpg';
import block_party_editorials_2 from '../assets/images/catalogue-2022/BLOCK-PARTY/CAMPAGNA-ADV/2.jpg';
import block_party_editorials_3 from '../assets/images/catalogue-2022/BLOCK-PARTY/CAMPAGNA-ADV/3.jpg';
import block_party_editorials_4 from '../assets/images/catalogue-2022/BLOCK-PARTY/CAMPAGNA-ADV/4.jpg';
import block_party_editorials_5 from '../assets/images/catalogue-2022/BLOCK-PARTY/CAMPAGNA-ADV/5.jpg';

import tropical_house_editorials_1 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/CAMPAGNA-ADV/1.jpg';
import tropical_house_editorials_2 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/CAMPAGNA-ADV/2.jpg';
import tropical_house_editorials_3 from '../assets/images/catalogue-2022/TROPICAL-HOUSE/CAMPAGNA-ADV/3.jpg';

export const catalogueData = {
  home: {
    title: 'title',
    buttonText: 'button',
    Explore_our_latest_arrivals: 'Explore_our_latest_arrivals',
    video: {
      url: 'https://player.vimeo.com/progressive_redirect/playback/678716825/rendition/1080p?loc=external&signature=c5d550925afb84242048da92b4e16b82d3a149fdbb3bc2cde8b05e3dc98cb6c6',
      type: 'video/mp4',
    },
  },
  locales: ['it', 'en', 'fr', 'es', 'de', 'pt', 'jp', 'cn', 'ru'],
  menus: [
    {
      id: 0,
      title: 'menus.everything_from_a_to_x',
      url: '/collections',
      param: 'everythingFromAtoX',
      new_arrival: false,
      slide_lb_first_index: 0,
      slide_lb_last_index: 9,
      slide_ed_first_index: 0,
      slide_ed_last_index: 5,
      slide_lb_first_index_m: 0,
      slide_lb_last_index_m: 1,
      slide_ed_first_index_m: 0,
      slide_ed_last_index_m: 0,
      background_image: everything_from_a_to_x_1,
    },
    {
      id: 1,
      title: 'menus.primavera_sound',
      url: '/collections',
      param: 'primaveraSound',
      new_arrival: false,
      slide_lb_first_index: 10,
      slide_lb_last_index: 19,
      slide_ed_first_index: 6,
      slide_ed_last_index: 13,
      slide_lb_first_index_m: 1,
      slide_lb_last_index_m: 2,
      slide_ed_first_index_m: 1,
      slide_ed_last_index_m: 1,
      background_image: primavera_sound_1,
    },
    {
      id: 2,
      title: 'menus.desert_festival',
      url: '/collections',
      param: 'desertFestival',
      new_arrival: false,
      slide_lb_first_index: 20,
      slide_lb_last_index: 34,
      slide_ed_first_index: 14,
      slide_ed_last_index: 21,
      slide_lb_first_index_m: 2,
      slide_lb_last_index_m: 3,
      slide_ed_first_index_m: 2,
      slide_ed_last_index_m: 2,
      background_image: desert_festival_1,
    },
    {
      id: 3,
      title: 'menus.ax_beats',
      url: '/collections',
      param: 'axBeats',
      new_arrival: false,
      slide_lb_first_index: 35,
      slide_lb_last_index: 47,
      slide_ed_first_index: 22,
      slide_ed_last_index: 30,
      slide_lb_first_index_m: 3,
      slide_lb_last_index_m: 4,
      slide_ed_first_index_m: 3,
      slide_ed_last_index_m: 3,
      background_image: ax_beats_1,
    },
    {
      id: 4,
      title: 'menus.denim_love',
      url: '/collections',
      param: 'denimLove',
      new_arrival: false,
      slide_lb_first_index: 48,
      slide_lb_last_index: 55,
      slide_ed_first_index: 31,
      slide_ed_last_index: 35,
      slide_lb_first_index_m: 4,
      slide_lb_last_index_m: 5,
      slide_ed_first_index_m: 4,
      slide_ed_last_index_m: 4,
      background_image: denim_love_1,
    },
    {
      id: 5,
      title: 'menus.sunset_chill',
      url: '/collections',
      param: 'sunsetChill',
      new_arrival: false,
      slide_lb_first_index: 56,
      slide_lb_last_index: 61,
      slide_ed_first_index: 36,
      slide_ed_last_index: 41,
      slide_lb_first_index_m: 5,
      slide_lb_last_index_m: 6,
      slide_ed_first_index_m: 5,
      slide_ed_last_index_m: 5,
      background_image: sunset_chill_1,
    },
    {
      id: 6,
      title: 'menus.block_party',
      url: '/collections',
      param: 'blockParty',
      new_arrival: false,
      slide_lb_first_index: 62,
      slide_lb_last_index: 73,
      slide_ed_first_index: 42,
      slide_ed_last_index: 47,
      slide_lb_first_index_m: 6,
      slide_lb_last_index_m: 7,
      slide_ed_first_index_m: 6,
      slide_ed_last_index_m: 6,
      background_image: block_party_1,
    },
    {
      id: 7,
      title: 'menus.tropical_house',
      url: '/collections',
      param: 'tropicalHouse',
      new_arrival: false,
      slide_lb_first_index: 74,
      slide_lb_last_index: 84,
      slide_ed_first_index: 48,
      slide_ed_last_index: 51,
      slide_lb_first_index_m: 7,
      slide_lb_last_index_m: 8,
      slide_ed_first_index_m: 7,
      slide_ed_last_index_m: 7,
      background_image: tropical_house_1,
    },
    {
      id: 8,
      url: '/collections',
      param: 'xyz',
      new_arrival: false,
      slide_lb_first_index: 85,
      slide_lb_last_index: 85,
      slide_ed_first_index: 52,
      slide_ed_last_index: 52,
      slide_lb_first_index_m: 8,
      slide_lb_last_index_m: 9,
      slide_ed_first_index_m: 8,
      slide_ed_last_index_m: 8,
    },
  ],

  lookBooks: [
    {
      id: 'lb_eax',
      title: 'everything_from_a_to_x.title',
      title1: 'everything_from_a_to_x.title1',
      description: 'everything_from_a_to_x.description',
      dataHash: 'lb_anniversary',
      slide_number: 0,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/from-a-to-x',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/from-a-to-x',
        },
      ],
      collection_images: [
        {
          id: 'lb_eax_image_1',
          url: everything_from_a_to_x_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '33%',
              left: '12%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '38%',
              left: '8%',
              product_name: 'products.t-shirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804521.html',
            },
            {
              id: 3,
              top: '53%',
              left: '5%',
              product_name: 'products.shorts',
            },
            {
              id: 4,
              top: '21%',
              left: '5%',
              product_name: 'products.hat',
            },
            {
              id: 5,
              top: '48%',
              left: '8%',
              product_name: 'products.crossbody',
            },
            {
              id: 6,
              top: '78%',
              left: '18%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197616.html',
            },
            {
              id: 7,
              top: '32%',
              left: '70%',
              product_name: 'products.trench',
            },
            {
              id: 8,
              top: '43%',
              left: '58%',
              product_name: 'products.sweatshirt',
            },
            {
              id: 9,
              top: '52%',
              left: '60%',
              product_name: 'products.shorts',
            },
            {
              id: 10,
              top: '68%',
              left: '68%',
              product_name: 'products.bag',
            },
            {
              id: 11,
              top: '86%',
              left: '50%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_eax_image_2',
          url: everything_from_a_to_x_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '72%',
              left: '10%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197616.html',
            },
          ],
        },
        {
          id: 'lb_eax_image_3',
          url: everything_from_a_to_x_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '8%',
              left: '20%',
              product_name: 'products.hat',
            },
            {
              id: 2,
              top: '83%',
              left: '16%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_eax_image_4',
          url: everything_from_a_to_x_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '25%',
              left: '50%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '26%',
              left: '20%',
              product_name: 'products.t-shirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804521.html',
            },
            {
              id: 3,
              top: '53%',
              left: '16%',
              product_name: 'products.shorts',
            },
            {
              id: 4,
              top: '8%',
              left: '14%',
              product_name: 'products.hat',
            },
            {
              id: 5,
              top: '45%',
              left: '20%',
              product_name: 'products.crossbody',
            },
            {
              id: 6,
              top: '88%',
              left: '5%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197616.html',
            },
          ],
        },
        {
          id: 'lb_eax_image_5',
          url: everything_from_a_to_x_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '28%',
              left: '60%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '48%',
              left: '8%',
              product_name: 'products.sweatshirt',
            },
            {
              id: 3,
              top: '14%',
              left: '5%',
              product_name: 'products.brief',
            },
            {
              id: 4,
              top: '70%',
              left: '55%',
              product_name: 'products.5-pockets',
            },
          ],
        },
        {
          id: 'lb_eax_image_6',
          url: everything_from_a_to_x_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '40%',
              left: '5%',
              product_name: 'products.trench',
            },
            {
              id: 2,
              top: '32%',
              left: '13%',
              product_name: 'products.t-shirt',
            },
            {
              id: 3,
              top: '77%',
              left: '34%',
              product_name: 'products.trouser',
            },
            {
              id: 4,
              top: '10%',
              left: '11%',
              product_name: 'products.hat',
            },
            {
              id: 5,
              top: '60%',
              left: '20%',
              product_name: 'products.crossbody',
            },
            {
              id: 6,
              top: '44%',
              left: '45%',
              product_name: 'products.jackets',
            },
            {
              id: 7,
              top: '70%',
              left: '70%',
              product_name: 'products.shorts',
            },
            {
              id: 8,
              top: '28%',
              left: '72%',
              product_name: 'products.hat',
            },
            {
              id: 9,
              top: '50%',
              left: '70%',
              product_name: 'products.backpack',
            },
            {
              id: 10,
              top: '91%',
              left: '37%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_eax_image_7',
          url: everything_from_a_to_x_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '16%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod12701207.html',
            },
            {
              id: 2,
              top: '61%',
              left: '43%',
              product_name: 'products.shirts',
            },
            {
              id: 3,
              top: '35%',
              left: '10%',
              product_name: 'products.pullover',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46801270.html',
            },
          ],
        },
        {
          id: 'lb_eax_image_8',
          url: everything_from_a_to_x_8,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '30%',
              left: '64%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '51%',
              left: '64%',
              product_name: 'products.trouser',
            },
            {
              id: 3,
              top: '60%',
              left: '55%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '81%',
              left: '31%',
              product_name: 'products.shoes',
            },
            {
              id: 5,
              top: '36%',
              left: '5%',
              product_name: 'products.dress',
            },
            {
              id: 6,
              top: '63%',
              left: '5%',
              product_name: 'products.5-pockets',
            },
            {
              id: 7,
              top: '50%',
              left: '13%',
              product_name: 'products.bag',
            },
            {
              id: 8,
              top: '75%',
              left: '11%',
              product_name: 'products.shoes',
            },
            {
              id: 9,
              top: '16%',
              left: '22%',
              product_name: 'products.baseballhat',
            },
          ],
        },
        {
          id: 'lb_eax_image_9',
          url: everything_from_a_to_x_9,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '25%',
              left: '7%',
              product_name: 'products.trench',
            },
            {
              id: 2,
              top: '33%',
              left: '21%',
              product_name: 'products.t-shirt',
            },
            {
              id: 3,
              top: '61%',
              left: '7%',
              product_name: 'products.trouser',
            },
            {
              id: 4,
              top: '8%',
              left: '17%',
              product_name: 'products.hat',
            },
            {
              id: 5,
              top: '41%',
              left: '19%',
              product_name: 'products.crossbody',
            },
            {
              id: 6,
              top: '81%',
              left: '5%',
              product_name: 'products.shoes',
            },
            {
              id: 7,
              top: '32%',
              left: '74%',
              product_name: 'products.blousonjacket',
            },
            {
              id: 8,
              top: '48%',
              left: '68%',
              product_name: 'products.dress',
            },
            {
              id: 9,
              top: '69%',
              left: '74%',
              product_name: 'products.5-pockets',
            },
            {
              id: 10,
              top: '55%',
              left: '60%',
              product_name: 'products.bag',
            },
            {
              id: 11,
              top: '84%',
              left: '30%',
              product_name: 'products.shoes',
            },
            {
              id: 12,
              top: '8%',
              left: '66%',
              product_name: 'products.baseballhat',
            },
          ],
        },
      ],
    },
    {
      id: 'lb_ps',
      title: 'primavera_sound.title',
      description: 'primavera_sound.description',
      dataHash: 'lb_anniversary',
      slide_number: 10,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/primavera-sound',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/primavera-sound',
        },
      ],
      collection_images: [
        {
          id: 'lb_ps_image_1',
          url: primavera_sound_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '25%',
              left: '54%',
              product_name: 'products.jackets',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15176524.html',
            },
            {
              id: 2,
              top: '30%',
              left: '32%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800773.html',
            },
            {
              id: 3,
              top: '53%',
              left: '56%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46796601.html',
            },
            {
              id: 4,
              top: '61%',
              left: '22%',
              product_name: 'products.backpack',
            },
            {
              id: 5,
              top: '10%',
              left: '23%',
              product_name: 'products.baseballhat',
            },
            {
              id: 6,
              top: '79%',
              left: '26%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_ps_image_2',
          url: primavera_sound_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '40%',
              left: '60%',
              product_name: 'products.jackets',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15169492.html',
            },
            {
              id: 2,
              top: '78%',
              left: '58%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46797948.html',
            },
            {
              id: 3,
              top: '86%',
              left: '5%',
              product_name: 'products.minipouch',
            },
            {
              id: 4,
              top: '3%',
              left: '13%',
              product_name: 'products.hat',
            },
          ],
        },
        {
          id: 'lb_ps_image_3',
          url: primavera_sound_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '25%',
              left: '57%',
              product_name: 'products.jackets',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15169623.html',
            },
            {
              id: 2,
              top: '34%',
              left: '18%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46798044.html',
            },
            {
              id: 3,
              top: '57%',
              left: '48%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800916.html',
            },
            {
              id: 4,
              top: '88%',
              left: '17%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_ps_image_4',
          url: primavera_sound_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '38%',
              left: '5%',
              product_name: 'products.blousonjacket',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15175236.html',
            },
            {
              id: 2,
              top: '60%',
              left: '53%',
              product_name: 'products.dress',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15174666.html',
            },
            {
              id: 3,
              top: '4%',
              left: '23%',
              product_name: 'products.baseballhat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804658.html',
            },
          ],
        },
        {
          id: 'lb_ps_image_5',
          url: primavera_sound_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '40%',
              left: '8%',
              product_name: 'products.jackets',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15169505.html',
            },
            {
              id: 2,
              top: '54%',
              left: '9%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46797948.html',
            },
            {
              id: 3,
              top: '63%',
              left: '21%',
              product_name: 'products.backpack',
            },
            {
              id: 4,
              top: '85%',
              left: '5%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197543.html',
            },
            {
              id: 5,
              top: '31%',
              left: '72%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14186427.html',
            },
            {
              id: 6,
              top: '60%',
              left: '70%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46796569.html',
            },
            {
              id: 7,
              top: '50%',
              left: '74%',
              product_name: 'products.techcase',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46807172.html',
            },
            {
              id: 8,
              top: '71%',
              left: '71%',
              product_name: 'products.shoes',
            },
            {
              id: 9,
              top: '20%',
              left: '71%',
              product_name: 'products.baseballhat',
            },
          ],
        },
        {
          id: 'lb_ps_image_6',
          url: primavera_sound_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '42%',
              left: '64%',
              product_name: 'products.jackets',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15183851.html',
            },
            {
              id: 2,
              top: '16%',
              left: '62%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14186427.html',
            },
            {
              id: 3,
              top: '85%',
              left: '12%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46796569.html',
            },
            {
              id: 4,
              top: '77%',
              left: '57%',
              product_name: 'products.techcase',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46807172.html',
            },
            {
              id: 5,
              top: '3%',
              left: '17%',
              product_name: 'products.baseballhat',
            },
          ],
        },
        {
          id: 'lb_ps_image_7',
          url: primavera_sound_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '45%',
              left: '5%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14186427.html',
            },
            {
              id: 2,
              top: '56%',
              left: '57%',
              product_name: 'products.techcase',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46807172.html',
            },
            {
              id: 3,
              top: '81%',
              left: '21%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46796569.html',
            },
          ],
        },
        {
          id: 'lb_ps_image_8',
          url: primavera_sound_8,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '26%',
              left: '62%',
              product_name: 'products.trench',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15175238.html',
            },
            {
              id: 2,
              top: '36%',
              left: '49%',
              product_name: 'products.shirts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46796433.html',
            },
            {
              id: 3,
              top: '52%',
              left: '14%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46801265.html',
            },
            {
              id: 4,
              top: '50%',
              left: '59%',
              product_name: 'products.bag',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod45630686.html',
            },
            {
              id: 5,
              top: '62%',
              left: '46%',
              product_name: 'products.iphoneholder',
            },
            {
              id: 6,
              top: '87%',
              left: '27%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_ps_image_9',
          url: primavera_sound_9,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '24%',
              left: '55%',
              product_name: 'products.blousonjacket',
            },
            {
              id: 2,
              top: '54%',
              left: '23%',
              product_name: 'products.jumpsuit',
            },
            {
              id: 3,
              top: '40%',
              left: '17%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '80%',
              left: '17%',
              product_name: 'products.shoes',
            },
            {
              id: 5,
              top: '5%',
              left: '27%',
              product_name: 'products.baseballhat',
            },
          ],
        },
      ],
    },
    {
      id: 'lb_df',
      title: 'desert_festival.title',
      description: 'desert_festival.description',
      dataHash: 'lb_anniversary',
      slide_number: 20,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/desert-festival',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/desert-festival',
        },
      ],
      collection_images: [
        {
          id: 'lb_df_image_1',
          url: desert_festival_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '24%',
              left: '34%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '40%',
              left: '31%',
              product_name: 'products.t-shirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800877.html',
            },
            {
              id: 3,
              top: '54%',
              left: '31%',
              product_name: 'products.shorts',
            },
            {
              id: 4,
              top: '4%',
              left: '32%',
              product_name: 'products.baseballhat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808128.html',
            },
            {
              id: 5,
              top: '20%',
              left: '73%',
              product_name: 'products.backpack',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod45639878.html',
            },
            {
              id: 6,
              top: '80%',
              left: '28%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197597.html',
            },
          ],
        },
        {
          id: 'lb_df_image_2',
          url: desert_festival_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '42%',
              left: '5%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197597.html',
            },
          ],
        },
        {
          id: 'lb_df_image_3',
          url: desert_festival_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '0%',
              left: '43%',
              product_name: 'products.baseballhat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808128.html',
            },
            {
              id: 2,
              top: '30%',
              left: '27%',
              product_name: 'products.backpack',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod45639878.html',
            },
            {
              id: 3,
              top: '70%',
              left: '8%',
              product_name: 'products.jackets',
            },
          ],
        },
        {
          id: 'lb_df_image_4',
          url: desert_festival_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '29%',
              left: '57%',
              product_name: 'products.trench',
            },
            {
              id: 2,
              top: '35%',
              left: '21%',
              product_name: 'products.top',
            },
            {
              id: 3,
              top: '58%',
              left: '22%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804684.html',
            },
            {
              id: 4,
              top: '41%',
              left: '47%',
              product_name: 'products.iphoneholder',
            },
            {
              id: 5,
              top: '83%',
              left: '26%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_df_image_5',
          url: desert_festival_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '30%',
              left: '32%',
              product_name: 'products.shirts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800984.html',
            },
            {
              id: 2,
              top: '80%',
              left: '18%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46803937.html',
            },
            {
              id: 3,
              top: '31%',
              left: '69%',
              product_name: 'products.backpack',
            },
            {
              id: 4,
              top: '3%',
              left: '24%',
              product_name: 'products.hat',
            },
            {
              id: 5,
              top: '70%',
              left: '58%',
              product_name: 'products.minipouch',
            },
          ],
        },
        {
          id: 'lb_df_image_6',
          url: desert_festival_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '20%',
              left: '20%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '20%',
              left: '54%',
              product_name: 'products.t-shirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800813.html',
            },
            {
              id: 3,
              top: '55%',
              left: '16%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46803945.html',
            },
            {
              id: 4,
              top: '4%',
              left: '31%',
              product_name: 'products.baseballhat',
            },
            {
              id: 5,
              top: '35%',
              left: '55%',
              product_name: 'products.techcase',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46807172.html',
            },
            {
              id: 6,
              top: '86%',
              left: '8%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_df_image_7',
          url: desert_festival_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '27%',
              left: '63%',
              product_name: 'products.dress',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15178747.html',
            },
            {
              id: 2,
              top: '40%',
              left: '22%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '82%',
              left: '10%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_df_image_8',
          url: desert_festival_8,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '23%',
              left: '54%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14194210.html',
            },
            {
              id: 2,
              top: '52%',
              left: '52%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46803937.html',
            },
            {
              id: 3,
              top: '40%',
              left: '18%',
              product_name: 'products.waistbag',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod45641577.html',
            },
            {
              id: 4,
              top: '6%',
              left: '24%',
              product_name: 'products.hat',
            },
          ],
        },
        {
          id: 'lb_df_image_9',
          url: desert_festival_9,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '35%',
              left: '20%',
              product_name: 'products.trench',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15182828.html',
            },
            {
              id: 2,
              top: '26%',
              left: '57%',
              product_name: 'products.top',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808183.html',
            },
            {
              id: 3,
              top: '53%',
              left: '60%',
              product_name: 'products.skirts',
            },
            {
              id: 4,
              top: '88%',
              left: '30%',
              product_name: 'products.shoes',
            },
            {
              id: 5,
              top: '72%',
              left: '18%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_df_image_10',
          url: desert_festival_10,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '27%',
              left: '56%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '56%',
              left: '17%',
              product_name: 'products.trouser',
            },
            {
              id: 3,
              top: '25%',
              left: '15%',
              product_name: 'products.waistbag',
            },
            {
              id: 4,
              top: '83%',
              left: '5%',
              product_name: 'products.shoes',
            },
            {
              id: 5,
              top: '9%',
              left: '32%',
              product_name: 'products.hat',
            },
          ],
        },
        {
          id: 'lb_df_image_11',
          url: desert_festival_11,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '56%',
              left: '12%',
              product_name: 'products.jackets',
            },
            {
              id: 2,
              top: '30%',
              left: '65%',
              product_name: 'products.sweatshirt',
            },
            {
              id: 4,
              top: '65%',
              left: '63%',
              product_name: 'products.shorts',
            },
            {
              id: 5,
              top: '5%',
              left: '25%',
              product_name: 'products.hat',
            },
            {
              id: 6,
              top: '81%',
              left: '19%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_df_image_12',
          url: desert_festival_12,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '42%',
              left: '8%',
              product_name: 'products.dress',
            },
            {
              id: 2,
              top: '60%',
              left: '45%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '88%',
              left: '5%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_df_image_13',
          url: desert_festival_13,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '25%',
              left: '58%',
              product_name: 'products.sweatshirt',
            },
            {
              id: 2,
              top: '44%',
              left: '20%',
              product_name: 'products.jackets',
            },
            {
              id: 3,
              top: '54%',
              left: '53%',
              product_name: 'products.shorts',
            },
            {
              id: 4,
              top: '64%',
              left: '56%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '8%',
              left: '28%',
              product_name: 'products.hat',
            },
            {
              id: 6,
              top: '77%',
              left: '49%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 7,
              top: '74%',
              left: '7%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197589.html',
            },
          ],
        },
        {
          id: 'lb_df_image_14',
          url: desert_festival_14,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '30%',
              left: '35%',
              product_name: 'products.trench',
            },
            {
              id: 2,
              top: '32%',
              left: '65%',
              product_name: 'products.top',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46801255.html',
            },
            {
              id: 3,
              top: '56%',
              left: '71%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804596.html',
            },
            {
              id: 4,
              top: '44%',
              left: '72%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '84%',
              left: '33%',
              product_name: 'products.shoes',
            },
            {
              id: 6,
              top: '42%',
              left: '5%',
              product_name: 'products.dress',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15178726.html',
            },
            {
              id: 7,
              top: '66%',
              left: '5%',
              product_name: 'products.5-pockets',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod12701133.html',
            },
            {
              id: 8,
              top: '76%',
              left: '26%',
              product_name: 'products.shoes',
            },
            {
              id: 9,
              top: '56%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
      ],
    },
    {
      id: 'lb_axb',
      title: 'ax_beats.title',
      description: 'ax_beats.description',
      dataHash: 'lb_anniversary',
      slide_number: 35,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/ax-beats',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/ax-beats',
        },
      ],
      collection_images: [
        {
          id: 'lb_axb_image_1',
          url: ax_beats_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '28%',
              left: '22%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14194491.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.t-shirt',
            },
            {
              id: 3,
              top: '60%',
              left: '60%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46803947.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '87%',
              left: '18%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197601.html',
            },
          ],
        },
        {
          id: 'lb_axb_image_2',
          url: ax_beats_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '30%',
              left: '20%',
              product_name: 'products.trench',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15185342.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '63%',
              left: '58%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46803935.html',
            },
            {
              id: 4,
              top: '5%',
              left: '58%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808115.html',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '76%',
              left: '22%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197627.html',
            },
          ],
        },
        {
          id: 'lb_axb_image_3',
          url: ax_beats_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '47%',
              left: '65%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 2,
              top: '64%',
              left: '8%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197601.html',
            },
          ],
        },
        {
          id: 'lb_axb_image_4',
          url: ax_beats_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '36%',
              left: '8%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14194432.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '5%',
              left: '12%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808115.html',
            },
            {
              id: 4,
              top: '76%',
              left: '20%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '86%',
              left: '17%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197601.html',
            },
            {
              id: 7,
              top: '40%',
              left: '70%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14191173.html',
            },
            {
              id: 8,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 9,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 10,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 11,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_axb_image_5',
          url: ax_beats_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '42%',
              left: '22%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14194432.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '7%',
              left: '25%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808115.html',
            },
            {
              id: 4,
              top: '76%',
              left: '60%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '82%',
              left: '18%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197601.html',
            },
          ],
        },
        {
          id: 'lb_axb_image_6',
          url: ax_beats_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '45%',
              left: '60%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14194430.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '3%',
              left: '17%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808115.html',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.shoes',
            },
          ],
        },
        {
          id: 'lb_axb_image_7',
          url: ax_beats_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '40%',
              left: '60%',
              product_name: 'products.blousonjacket',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15179014.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '67%',
              left: '21%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46803935.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '74%',
              left: '60%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 6,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 7,
              top: '85%',
              left: '20%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197627.html',
            },
          ],
        },
        {
          id: 'lb_axb_image_8',
          url: ax_beats_8,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '37%',
              left: '45%',
              product_name: 'products.t-shirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46802834.html',
            },
            {
              id: 3,
              top: '50%',
              left: '40%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800886.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 7,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_axb_image_9',
          url: ax_beats_9,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '36%',
              left: '50%',
              product_name: 'products.trench',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197627.html',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '64%',
              left: '64%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197627.html',
            },
            {
              id: 7,
              top: '20%',
              left: '54%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197627.html',
            },
            {
              id: 8,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 9,
              top: '73%',
              left: '37%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197627.html',
            },
          ],
        },
        {
          id: 'lb_axb_image_10',
          url: ax_beats_10,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '10%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14191173.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_axb_image_11',
          url: ax_beats_11,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '50%',
              left: '60%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46802833.html',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '50%',
              left: '35%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14191173.html',
            },
            {
              id: 7,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 8,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 9,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 10,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 11,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 12,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_axb_image_12',
          url: ax_beats_12,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
      ],
    },
    {
      id: 'lb_dl',
      title: 'denim_love.title',
      description: 'denim_love.description',
      dataHash: 'lb_anniversary',
      slide_number: 48,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/denim-love',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/denim-love',
        },
      ],
      collection_images: [
        {
          id: 'lb_dl_image_1',
          url: denim_love_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_dl_image_2',
          url: denim_love_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 7,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 8,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 9,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_dl_image_3',
          url: denim_love_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_dl_image_4',
          url: denim_love_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '55%',
              left: '45%',
              product_name: 'products.blousonjacket',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15176762.html',
            },
            {
              id: 6,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 7,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 8,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_dl_image_5',
          url: denim_love_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_dl_image_6',
          url: denim_love_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '60%',
              left: '25%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800695.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        // {
        //   id: 'lb_dl_image_7',
        //   url: denim_love_7,
        //   alt: '',
        //   caption: 'VEDI I DETTAGLI',
        //   tags: [
        //     {
        //       id: 1,
        //       top: '60%',
        //       left: '25%',
        //       product_name: 'products.blousonjacket',
        //       shop_online: 'tags.shop-now',
        //       url: 'https://www.armaniexchange.com/_cod15176762.html',
        //     },
        //   ],
        // },
      ],
    },
    {
      id: 'lb_sc',
      title: 'sunset_chill.title',
      description: 'sunset_chill.description',
      dataHash: 'lb_anniversary',
      slide_number: 56,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/sunset-chill',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/sunset-chill',
        },
      ],
      collection_images: [
        {
          id: 'lb_sc_image_1',
          url: sunset_chill_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '8%',
              left: '34%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808115.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_sc_image_2',
          url: sunset_chill_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '80%',
              left: '12%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197632.html',
            },
            {
              id: 5,
              top: '39%',
              left: '55%',
              product_name: 'products.belt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46809647.html',
            },
            {
              id: 6,
              top: '7%',
              left: '23%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808115.html',
            },
          ],
        },
        {
          id: 'lb_sc_image_3',
          url: sunset_chill_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '50%',
              left: '20%',
              product_name: 'products.dress',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15183185.html',
            },
            {
              id: 2,
              top: '60%',
              left: '65%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804588.html',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '42%',
              left: '63%',
              product_name: 'products.bag',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804836.html',
            },
          ],
        },
        {
          id: 'lb_sc_image_4',
          url: sunset_chill_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '25%',
              left: '52%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14194428.html',
            },
            {
              id: 3,
              top: '25%',
              left: '52%',
              product_name: 'products.t-shirt',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '74%',
              left: '49%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 7,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_sc_image_5',
          url: sunset_chill_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '40%',
              left: '20%',
              product_name: 'products.dress',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15182011.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
      ],
    },
    {
      id: 'lb_bp',
      title: 'block_party.title',
      description: 'block_party.description',
      dataHash: 'lb_anniversary',
      slide_number: 62,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/block-party',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/block-party',
        },
      ],
      collection_images: [
        {
          id: 'lb_bp_image_1',
          url: block_party_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '72%',
              left: '7%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 7,
              top: '82%',
              left: '8%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197589.html',
            },
          ],
        },
        {
          id: 'lb_bp_image_2',
          url: block_party_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '5%',
              left: '62%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808128.html',
            },
            {
              id: 7,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 8,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_bp_image_3',
          url: block_party_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '90%',
              left: '5%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 7,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_bp_image_4',
          url: block_party_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '40%',
              left: '20%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod14194437.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '8%',
              left: '20%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46810623.html',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '73%',
              left: '20%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 7,
              top: '75%',
              left: '55%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197589.html',
            },
          ],
        },
        {
          id: 'lb_bp_image_5',
          url: block_party_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '83%',
              left: '18%',
              product_name: 'products.shirts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804469.html',
            },
            {
              id: 2,
              top: '3%',
              left: '56%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808124.html',
            },
          ],
        },
        {
          id: 'lb_bp_image_6',
          url: block_party_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '38%',
              left: '30%',
              product_name: 'products.shirts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804469.html',
            },
            {
              id: 2,
              top: '63%',
              left: '20%',
              product_name: 'products.5-pockets',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod12701359.html',
            },
            {
              id: 3,
              top: '13%',
              left: '55%',
              product_name: 'products.hat',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46808124.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_bp_image_7',
          url: block_party_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.t-shirt',
            },
            {
              id: 4,
              top: '54%',
              left: '13%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804714.html',
            },
            {
              id: 5,
              top: '74%',
              left: '9%',
              product_name: 'products.socks',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod48214536.html',
            },
            {
              id: 6,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 7,
              top: '80%',
              left: '70%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17197630.html',
            },
          ],
        },
        {
          id: 'lb_bp_image_8',
          url: block_party_8,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '27%',
              left: '55%',
              product_name: 'products.trench',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15173707.html',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_bp_image_9',
          url: block_party_9,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '38%',
              left: '18%',
              product_name: 'products.sweatshirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46800931.html',
            },
            {
              id: 2,
              top: '60%',
              left: '18%',
              product_name: 'products.trouser',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804688.html',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_bp_image_10',
          url: block_party_10,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '38%',
              left: '28%',
              product_name: 'products.t-shirt',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46804600.html',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_bp_image_11',
          url: block_party_11,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '64%',
              left: '22%',
              product_name: 'products.skirts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46801749.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
      ],
    },
    {
      id: 'lb_th',
      title: 'tropical_house.title',
      description: 'tropical_house.description',
      dataHash: 'lb_anniversary',
      slide_number: 74,
      have_nav: true,
      nav_title: 'collection.shop_now',
      navs: [
        {
          title: 'collection.men',
          url: 'https://www.armaniexchange.com/men/tropical-house',
        },
        {
          title: 'collection.women',
          url: 'https://www.armaniexchange.com/women/tropical-house',
        },
      ],
      collection_images: [
        {
          id: 'lb_th_image_1',
          url: tropical_house_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '47%',
              left: '17%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46802815.html',
            },
            {
              id: 4,
              top: '30%',
              left: '55%',
              product_name: 'products.bag',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod45641576.html',
            },
            {
              id: 5,
              top: '70%',
              left: '50%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17180784.html',
            },
          ],
        },
        {
          id: 'lb_th_image_2',
          url: tropical_house_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '55%',
              left: '60%',
              product_name: 'products.shorts',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46802815.html',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 6,
              top: '87%',
              left: '14%',
              product_name: 'products.shoes',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod17180784.html',
            },
          ],
        },
        {
          id: 'lb_th_image_3',
          url: tropical_house_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_th_image_4',
          url: tropical_house_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_th_image_5',
          url: tropical_house_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_th_image_6',
          url: tropical_house_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_th_image_7',
          url: tropical_house_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '47%',
              left: '23%',
              product_name: 'products.top',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod46810120.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_th_image_8',
          url: tropical_house_8,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '60%',
              left: '25%',
              product_name: 'products.dress',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15178881.html',
            },
            {
              id: 2,
              top: '50%',
              left: '60%',
              product_name: 'products.trench',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15178860.html',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_th_image_9',
          url: tropical_house_9,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 2,
              top: '38%',
              left: '66%',
              product_name: 'products.blousonjacket',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15183190.html',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
        {
          id: 'lb_th_image_10',
          url: tropical_house_10,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [
            {
              id: 1,
              top: '27%',
              left: '42%',
              product_name: 'products.dress',
              shop_online: 'tags.shop-now',
              url: 'https://www.armaniexchange.com/_cod15183189.html',
            },
            {
              id: 2,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 3,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 4,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
            {
              id: 5,
              top: '70%',
              left: '5%',
              product_name: 'products.bag',
            },
          ],
        },
      ],
    },
    {
      id: 'lb_xyz',
      slide_number: 85,
      navs: [],
      collection_images: [],
    },
  ],
  editorials: [
    {
      id: 'ed_eax',
      title: 'everything_from_a_to_x.title',
      title1: 'everything_from_a_to_x.title1',
      description: 'everything_from_a_to_x.description',
      have_nav: false,
      slide_number: 0,
      navs: [],
      collection_images: [
        {
          id: 'ed_eax_image_1',
          url: everything_from_a_to_x_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_eax_image_2',
          url: everything_from_a_to_x_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_eax_image_3',
          url: everything_from_a_to_x_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_eax_image_4',
          url: everything_from_a_to_x_editorials_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_eax_image_5',
          url: everything_from_a_to_x_editorials_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_ps',
      title: 'primavera_sound.title',
      description: 'primavera_sound.description',
      have_nav: false,
      slide_number: 6,
      navs: [],
      collection_images: [
        {
          id: 'ed_ps_image_1',
          url: primavera_sound_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_ps_image_2',
          url: primavera_sound_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_ps_image_3',
          url: primavera_sound_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_ps_image_4',
          url: primavera_sound_editorials_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_ps_image_5',
          url: primavera_sound_editorials_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_ps_image_6',
          url: primavera_sound_editorials_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_ps_image_7',
          url: primavera_sound_editorials_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_df',
      title: 'desert_festival.title',
      description: 'desert_festival.description',
      have_nav: false,
      slide_number: 14,
      navs: [],
      collection_images: [
        {
          id: 'ed_df_image_1',
          url: desert_festival_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_df_image_2',
          url: desert_festival_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_df_image_3',
          url: desert_festival_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_df_image_4',
          url: desert_festival_editorials_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_df_image_5',
          url: desert_festival_editorials_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_df_image_6',
          url: desert_festival_editorials_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ed_df_image_7',
          url: desert_festival_editorials_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_axb',
      title: 'ax_beats.title',
      description: 'ax_beats.description',
      have_nav: false,
      slide_number: 22,
      navs: [],
      collection_images: [
        {
          id: 'ax_beats_image_1',
          url: ax_beats_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ax_beats_image_2',
          url: ax_beats_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ax_beats_image_3',
          url: ax_beats_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ax_beats_image_4',
          url: ax_beats_editorials_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ax_beats_image_5',
          url: ax_beats_editorials_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ax_beats_image_6',
          url: ax_beats_editorials_6,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ax_beats_image_7',
          url: ax_beats_editorials_7,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'ax_beats_image_8',
          url: ax_beats_editorials_8,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_dl',
      title: 'denim_love.title',
      description: 'denim_love.description',
      have_nav: false,
      slide_number: 31,
      navs: [],
      collection_images: [
        {
          id: 'denim_love_image_1',
          url: denim_love_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'denim_love_image_2',
          url: denim_love_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'denim_love_image_3',
          url: denim_love_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'denim_love_image_4',
          url: denim_love_editorials_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_sc',
      title: 'sunset_chill.title',
      description: 'sunset_chill.description',
      have_nav: false,
      slide_number: 36,
      navs: [],
      collection_images: [
        {
          id: 'sunset_chill_image_1',
          url: sunset_chill_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'sunset_chill_image_2',
          url: sunset_chill_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'sunset_chill_image_3',
          url: sunset_chill_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'sunset_chill_image_4',
          url: sunset_chill_editorials_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'sunset_chill_image_5',
          url: sunset_chill_editorials_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_bp',
      title: 'block_party.title',
      description: 'block_party.description',
      have_nav: false,
      slide_number: 42,
      navs: [],
      collection_images: [
        {
          id: 'block_party_image_1',
          url: block_party_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'block_party_image_2',
          url: block_party_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'block_party_image_3',
          url: block_party_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'block_party_image_4',
          url: block_party_editorials_4,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'block_party_image_5',
          url: block_party_editorials_5,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_th',
      title: 'tropical_house.title',
      description: 'tropical_house.description',
      have_nav: false,
      slide_number: 48,
      navs: [],
      collection_images: [
        {
          id: 'tropical_house_image_1',
          url: tropical_house_editorials_1,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'tropical_house_image_2',
          url: tropical_house_editorials_2,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
        {
          id: 'tropical_house_image_3',
          url: tropical_house_editorials_3,
          alt: '',
          caption: 'VEDI I DETTAGLI',
          tags: [],
        },
      ],
    },
    {
      id: 'ed_xyz',
      slide_number: 52,
      navs: [],
      collection_images: [],
    },
  ],
};
