import React from 'react';
import Styles from './button.module.scss';

const Button = ({ children, className, onClick, id }) => {
  return (
    <>
      <button
        className={`${className ? className : Styles.armaniBtn}`}
        onClick={onClick}
        id={id}
      >
        {children}
      </button>
    </>
  );
};

export default Button;
