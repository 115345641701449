import React, { useContext, useState } from 'react';
import Styles from './language.module.scss';
import { FaChevronDown } from 'react-icons/fa';
import i18n from '../../i18n';
import Button from '../Button';
import OutsideAlerter from '../OutsideClick';
import DataContext from '../../context';

const Language = ({
  inlineLanguage = false,
  toggleMenu,
  language,
  setLanguage,
  isMenu,
}) => {
  const { locales } = useContext(DataContext);
  const [dropdown, setDropdown] = useState(false);
  const changeLanguage = async (locale) => {
    try {
      await i18n.changeLanguage(locale);
      localStorage.setItem('storedLanguage', locale);
      setLanguage(locale);
      inlineLanguage && toggleMenu();
    } catch (e) {}
  };

  const openDropdown = () => {
    setDropdown(true);
  };
  const closeDropdown = () => {
    setDropdown(false);
  };

  return (
    <OutsideAlerter onClick={closeDropdown}>
      <>
        {!inlineLanguage && !dropdown && (
          <Button
            onClick={openDropdown}
            className={`btn ${Styles.btnLanguage}`}
          >
            {language || localStorage.getItem('storedLanguage')}
            <FaChevronDown />
          </Button>
        )}
        {(dropdown || inlineLanguage) && (
          <div
            onClick={closeDropdown}
            className={`${
              inlineLanguage
                ? Styles.inlineLanguage
                : Styles.languageOptions
            } ${isMenu ? Styles.languageDropDown : ''}`}
          >
            <ul className={inlineLanguage ? Styles.languageList : ''}>
              {locales?.map((lang, idx) => (
                <li
                  key={idx}
                  className={language === lang ? Styles.active : ''}
                  onClick={() => {
                    changeLanguage(lang);
                  }}
                >
                  {lang}
                </li>
              ))}
            </ul>
          </div>
        )}
      </>
    </OutsideAlerter>
  );
};

export default Language;
