import React, { useRef, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import Styles from './thumbslider.module.scss';

import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/thumbs/thumbs.min.css';
import { Link } from 'react-router-dom';

const ThumbSlider = ({
  menus,
  setSlide,
  currentSlide,
  prevRef,
  nextRef,
  activeTab,
  slideRoute,
  slideChange,
  setThumbSlideMenu,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    menus.map(
      ({ param, slide_lb_first_index, slide_ed_first_index }) => {
        if (slideRoute === param) {
          setSlide(
            activeTab === 'lookBook'
              ? slide_lb_first_index
              : slide_ed_first_index,
          );
        }
      },
    );

    return () => {};
  }, [slideRoute]);

  const swiperRef = useRef(null);

  return (
    <div className={Styles.thumbSlideContainer}>
      <Swiper
        loop={false}
        spaceBetween={10}
        slidesPerView={3}
        // freeMode={true}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
        breakpoints={{
          375: {
            slidesPerView: 3,
          },
          850: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 3,
          },
        }}
        ref={swiperRef}
        className="mySwiper"
      >
        {menus
          .filter((menu) => {
            return (
              activeTab === 'editorial' || activeTab === 'lookBook'
            );
          })
          .map(
            (
              {
                id,
                title,
                background_image,
                slide_lb_first_index,
                slide_ed_first_index,
                url,
                param,
              },
              idx,
            ) => (
              <div key={`${idx}`}>
                {/* todo: remove the condition when the second delivery goes live */}
                {id !== 8 && (
                  <SwiperSlide
                    id="swiperSlideCustomlast"
                    className="swiperSlideCustom"
                    key={idx}
                    onClick={() => {
                      setSlide(
                        activeTab === 'lookBook'
                          ? slide_lb_first_index
                          : slide_ed_first_index,
                      );
                    }}
                  >
                    <Link
                      to={`${url}/${param}`}
                      className={Styles.slideNav}
                    >
                      <div
                        className={`${Styles.thumbSlide} ${
                          currentSlide ===
                          (activeTab === 'lookBook'
                            ? slide_lb_first_index
                            : slide_ed_first_index)
                            ? Styles.active
                            : ''
                        }`}
                        style={{
                          backgroundImage:
                            'url(' + background_image + ')',
                        }}
                      ></div>
                      <div className={Styles.thumbSlideTitle}>
                        {t(title)}
                      </div>
                    </Link>
                  </SwiperSlide>
                )}
              </div>
            ),
          )}
      </Swiper>
    </div>
  );
};

export default ThumbSlider;
