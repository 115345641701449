import React, {
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import Styles from './anniversary.module.scss';
import './Anniversarymobile.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import Lightbox from 'react-image-lightbox';
import { useTranslation } from 'react-i18next';
import SwiperCore, {
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  Thumbs,
} from 'swiper/core';

import ThumbSlider from '../../components/ThumbSlider';
import { useIsMobile } from '../../utils/customHooks';
import DataContext from '../../context';

import 'react-image-lightbox/style.css';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import MobileExploreSlide from '../../components/MobileExploreSlide';
import Tags from '../../components/Tags';
import { isBetween } from '../../utils';
import TitleSlide from '../../components/TitleSlide';
import Tabs from '../../components/Tabs';
import LightBox from '../../components/Lightbox';
import { useHistory, useLocation } from 'react-router-dom';
import ThumbSliderMobile from '../../components/ThumbSliderMobile';
import { Link } from 'react-router-dom';
import { isEqual } from '../../utils';

SwiperCore.use([
  EffectFade,
  Navigation,
  Pagination,
  Mousewheel,
  Thumbs,
]);

const Anniversary = ({ params }) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const historyVar = useHistory();
  const { editorials, lookBooks, menus } = useContext(DataContext);
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('lookBook');
  const [currentPath, setCurrentPath] = useState();

  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const swiperRef = useRef(null);
  const firstSlide = useRef(null);
  const swiperRefVertical = useRef(null);

  const slideRef0 = useRef(null);
  const slideRef1 = useRef(null);
  const slideRef2 = useRef(null);

  const [thumbSlideMenu, setThumbSlideMenu] = useState(0);
  const [slide, setSlide] = useState(0);
  const [slideVertical, setSlideVertical] = useState(0);
  const [lightBoxImages, setLightBoxImages] = useState([]);
  const [photoIndex, setphotoIndex] = useState(0);
  const [isOpenLightBox, setIsOpenLightBox] = useState(false);
  const [slideChange, setSlideChange] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [thumbsSwiperEditorial, setThumbsSwiperEditorial] =
    useState(null);
  const [scroll, setScroll] = useState(0);

  const onVerticalSlideChange = (activeIndex) => {
    if (activeIndex == 0) {
      history.push('/collections/everythingFromAtoX');
    } else if (activeIndex == 1) {
      history.push('/collections/primaveraSound');
    } else if (activeIndex == 2) {
      history.push('/collections/desertFestival');
    }
  };

  const onLoadCollection = () => {
    if (pathname == '/collections/everythingFromAtoX') {
      document.getElementById(`mainSlide0`).scrollIntoView();
      document
        .getElementById(`tumbSlide0`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide1`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide2`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide3`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide4`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide5`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide6`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide7`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    } else if (pathname == '/collections/primaveraSound') {
      document.getElementById(`mainSlide1`).scrollIntoView();
      document
        .getElementById(`tumbSlide1`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide0`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide2`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide3`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide4`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide5`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide6`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide7`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    } else if (pathname == '/collections/desertFestival') {
      document.getElementById(`mainSlide2`).scrollIntoView();
      document
        .getElementById(`tumbSlide2`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide0`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide1`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide3`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide4`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide5`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide6`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide7`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    } else if (pathname == '/collections/axBeats') {
      document.getElementById(`mainSlide3`).scrollIntoView();
      document
        .getElementById(`tumbSlide3`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide0`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide1`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide2`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide4`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide5`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide6`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide7`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    } else if (pathname == '/collections/denimLove') {
      document.getElementById(`mainSlide4`).scrollIntoView();
      document
        .getElementById(`tumbSlide4`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide0`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide1`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide2`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide3`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide5`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide6`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide7`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    } else if (pathname == '/collections/sunsetChill') {
      document.getElementById(`mainSlide5`).scrollIntoView();
      document
        .getElementById(`tumbSlide5`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide0`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide1`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide2`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide3`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide4`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide6`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide7`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    } else if (pathname == '/collections/blockParty') {
      document.getElementById(`mainSlide6`).scrollIntoView();
      document
        .getElementById(`tumbSlide6`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide0`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide1`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide2`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide3`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide4`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide5`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide7`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    } else if (pathname == '/collections/tropicalHouse') {
      document.getElementById(`mainSlide7`).scrollIntoView();
      document
        .getElementById(`tumbSlide7`)
        .classList.add('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide0`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide1`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide2`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide3`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide4`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide5`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
      document
        .getElementById(`tumbSlide6`)
        .classList.remove('thumbSliderMobile_active__1n7h4');
    }
  };

  const customLookbookIds = [
    'lb_eax',
    'lb_ps',
    'lb_df',
    'lb_axb',
    'lb_dl',
    'lb_sc',
    'lb_bp',
    'lb_th',
    'lb_xyz',
  ];
  const customEditorialsIds = [
    'ed_eax',
    'ed_ps',
    'ed_df',
    'ed_axb',
    'ed_dl',
    'ed_sc',
    'ed_bp',
    'ed_th',
    'ed_xyz',
  ];

  useEffect(() => {
    document.body.style.overflow = 'auto';
    if (slideRef1 && slideRef1.current) {
      slideRef1.current.click();
    }
    return () => {};
  }, []);

  const isM = useIsMobile();

  const swiperProps = {
    className: Styles.swiperContainer,
    spaceBetween: 10,
    loop: false,
    navigation: isM ? true : false,
    freeMode: !isM ? true : false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    slidesPerView: 'auto',
    mousewheel: {
      sensitivity: 2,
    },
    hashNavigation: {
      watchState: true,
    },
    pagination: {
      dynamicBullets: true,
      el: '.custom-pagination',
      renderBullet: (index, className) => {
        return `<span class="${className} custom-pagination-bullet">${
          index + 1
        }</span>`;
      },
    },
    style: {
      '--swiper-navigation-color': 'grey',
    },
  };

  useEffect(() => {
    // if (slide != null) {
    //   swiperRef.current.swiper.slideTo(slide);
    // }
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slideTo(slide);
      history.push(pathname);
    }
    if (swiperRefVertical && swiperRefVertical.current) {
    }
    return () => {};
  }, [slide]);

  useEffect(() => {
    if (currentPath) {
      menus.map(
        ({
          param,
          slide_lb_first_index,
          slide_ed_first_index,
          slide_lb_first_index_m,
          slide_ed_first_index_m,
        }) => {
          if (currentPath === param) {
            // setSlide(
            //   activeTab === 'lookBook'
            //     ? slide_lb_first_index
            //     : slide_ed_first_index,
            // );
            !isM &&
              setSlide(
                activeTab === 'lookBook'
                  ? slide_lb_first_index
                  : slide_ed_first_index,
              );

            isM &&
              setSlide(
                activeTab === 'lookBook'
                  ? slide_lb_first_index_m
                  : slide_ed_first_index_m,
              );
          }
        },
      );
    }
    return () => {
      setCurrentPath(null);
    };
  }, [currentPath]);

  const toggle = (tab) => {
    // const path = pathname;
    if (tab == 'editorial') {
      setThumbsSwiper(null);
    } else setThumbsSwiperEditorial(null);
    setActiveTab(tab);
    setCurrentPath(params?.slide);
  };

  const openLightBox = (images, index) => {
    setphotoIndex(index);
    setLightBoxImages(images);
    setIsOpenLightBox(true);
  };

  const closeLightBox = (event) => {
    // event.preventDefault();
    let dataValue = event.target.getAttribute('data-value');
    if (
      dataValue === 'slides' ||
      dataValue === 'lightbox' ||
      dataValue === 'closeButton' ||
      dataValue === 'closeLabel'
    ) {
      setIsOpenLightBox(false);
    }
  };
  const nextSlide = () => {
    setphotoIndex(
      (photoIndex + lightBoxImages.length - 1) %
        lightBoxImages.length,
    );
  };

  const onSlideChange = (activeIndex) => {
    setSlideChange(activeIndex);
    menus.map(
      ({
        param,
        slide_lb_first_index,
        slide_lb_last_index,
        slide_ed_first_index,
        slide_ed_last_index,
        slide_lb_first_index_m,
        slide_lb_last_index_m,
        slide_ed_first_index_m,
        slide_ed_last_index_m,
        url,
        id,
      }) => {
        if (
          activeTab === 'lookBook' &&
          !isM &&
          isBetween(
            slide_lb_first_index,
            slide_lb_last_index,
            activeIndex,
          )
        ) {
          setSlideIndex(id);
          setThumbSlideMenu(id);
          history.push(`${url}/${param}`);
        } else if (
          activeTab === 'lookBook' &&
          isM &&
          isBetween(
            slide_lb_first_index_m,
            slide_lb_last_index_m,
            activeIndex,
          )
        ) {
          setSlideIndex(id);
          setThumbSlideMenu(id);
        } else if (
          (activeTab === 'editorial' &&
            !isM &&
            isBetween(
              slide_ed_first_index,
              slide_ed_last_index,
              activeIndex,
            )) ||
          (activeTab === 'editorial' &&
            isM &&
            isBetween(
              slide_ed_first_index_m,
              slide_ed_last_index_m,
              activeIndex,
            ))
        ) {
          setSlideIndex(id);
          history.push(`${url}/${param}`);
        }
      },
    );
  };

  // window.addEventListener('scroll', function () {
  //   document.getElementById('showScroll').innerHTML =
  //     window.pageYOffset + 'px';
  //   let i = window.pageYOffset;
  //   if (i > 0 ) {
  //     history.push('/collections/everythingFromAtoX');
  //   }
  //   if (i > 1200 && i < 2400) {
  //     history.push('/collections/primaveraSound');
  //   }
  //   if (i > 2400) {
  //     history.push('/collections/desertFestival');
  //   }
  // });

  // let i = window.pageYOffset;
  // console.log(i);

  return (
    <>
      {!isM && (
        <div className={Styles.anniversaryPage}>
          <Tabs
            thumbSlide={thumbSlideMenu}
            toggle={toggle}
            activeTab={activeTab}
            collectionLabel={t('tabs.collection')}
            editorialLabel={t('tabs.campaign_adv')}
          />
          <div>
            {activeTab === 'lookBook' && (
              <>
                <Swiper
                  {...swiperProps}
                  onSlideChange={({ activeIndex }) =>
                    onSlideChange(activeIndex)
                  }
                  ref={swiperRef}
                >
                  {lookBooks.map(
                    (
                      {
                        title,
                        title1,
                        description,
                        have_nav,
                        nav_title,
                        navs,
                        collection_images,
                        dataHash,
                      },
                      idx,
                    ) => (
                      <div key={`${idx}`}>
                        <SwiperSlide
                          data-hash={dataHash}
                          className={`${Styles.slides} ${
                            Styles.titleSlide
                          } ${idx > 0 ? Styles.otherSlides : ''}`}
                          key={`${idx}`}
                        >
                          <TitleSlide
                            title={t(title)}
                            title1={t(title1)}
                            description={t(description)}
                            have_nav={have_nav}
                            nav_title={nav_title}
                            navs={navs}
                          />
                        </SwiperSlide>
                        {collection_images.length > 0 &&
                          collection_images.map(
                            (
                              { id, url, caption, alt, tags },
                              idx,
                            ) => (
                              <SwiperSlide
                                className={`${Styles.deliveriesSlides}`}
                                key={`${id}`}
                              >
                                <figure>
                                  <img
                                    onClick={() =>
                                      openLightBox(
                                        collection_images,
                                        idx,
                                      )
                                    }
                                    src={url}
                                    alt={alt}
                                    className="img-fluid"
                                  />
                                  <div>
                                    <Tags tags={tags} />
                                  </div>
                                </figure>
                              </SwiperSlide>
                            ),
                          )}
                      </div>
                    ),
                  )}
                </Swiper>
                <div className={Styles.paginationContainer}>
                  <div className="custom-pagination"></div>
                </div>
              </>
            )}
            {activeTab === 'editorial' && (
              <>
                <Swiper
                  {...swiperProps}
                  onSlideChange={({ activeIndex }) =>
                    onSlideChange(activeIndex)
                  }
                  ref={swiperRef}
                >
                  {editorials.map(
                    (
                      {
                        id,
                        title,
                        title1,
                        description,
                        have_nav,
                        nav_title,
                        navs,
                        collection_images,
                        dataHash,
                      },
                      idx,
                    ) => (
                      <div key={`${idx}`}>
                        {id && (
                          <SwiperSlide
                            data-hash={dataHash}
                            className={`${Styles.slides} ${
                              Styles.titleSlide
                            } ${idx > 0 ? Styles.otherSlides : ''}`}
                            key={`${idx}`}
                          >
                            {!isM && (
                              <TitleSlide
                                title={t(title)}
                                title1={t(title1)}
                                description={t(description)}
                                have_nav={have_nav}
                                nav_title={nav_title}
                                navs={navs}
                              />
                            )}
                          </SwiperSlide>
                        )}
                        {collection_images.length > 0 &&
                          collection_images.map(
                            (
                              { id, url, caption, alt, tags },
                              idx,
                            ) => (
                              <SwiperSlide
                                className={`${Styles.slides} ${Styles.deliveriesSlides}`}
                                key={`${id}`}
                              >
                                <figure>
                                  <img
                                    onClick={() =>
                                      openLightBox(
                                        collection_images,
                                        idx,
                                      )
                                    }
                                    src={url}
                                    alt={alt}
                                    className="img-fluid"
                                  />
                                  {/* TODO: Uncomment when Details available */}
                                  {/* <div>
                              <Tags tags={tags} />
                            </div> */}
                                </figure>
                              </SwiperSlide>
                            ),
                          )}
                      </div>
                    ),
                  )}
                </Swiper>
                <div className={Styles.paginationContainer}>
                  <div className="custom-pagination"></div>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {isM && (
        // <div className="mobile__anniversary">
        //   <Tabs
        //     toggle={toggle}
        //     activeTab={activeTab}
        //     collectionLabel={t('tabs.collection')}
        //     editorialLabel={t('tabs.campaign_adv')}
        //     thumbSlide={thumbSlideMenu}
        //   />

        //   {activeTab === 'lookBook' && (
        //     <>
        //       <Swiper
        //         className="mySwiper2"
        //         slidesPerView={'auto'}
        //         direction={'vertical'}
        //         thumbs={{ swiper: thumbsSwiper }}
        //         freeMode={true}
        //         mousewheel={true}
        //         ref={swiperRefVertical}
        //         onAfterInit={() => {
        //           onLoadCollection();
        //         }}
        //         onSlideChange={({ activeIndex }) =>
        //           onVerticalSlideChange(activeIndex)
        //         }
        //       >
        //         {lookBooks.map(
        //           (
        //             {
        //               title,
        //               title1,
        //               description,
        //               have_nav,
        //               nav_title,
        //               navs,
        //               collection_images,
        //               dataHash,
        //             },
        //             idx,
        //           ) => (
        //             <div key={`${idx}`}>
        //               <SwiperSlide
        //                 key={`${idx}`}
        //                 id={`mainSlide${idx}`}
        //                 className="swiper__slide1 swiper__lookbook"
        //               >
        //                 <div className="mobile__title__slide">
        //                   <TitleSlide
        //                     title={t(title)}
        //                     title1={t(title1)}
        //                     description={t(description)}
        //                   />
        //                 </div>
        //                 <Swiper
        //                   className="mySwiper3"
        //                   slidesPerView={1.2}
        //                   spaceBetween={20}
        //                 >
        //                   {collection_images.length > 0 &&
        //                     collection_images.map(
        //                       (
        //                         { id, url, caption, alt, tags },
        //                         idx,
        //                       ) => (
        //                         <SwiperSlide>
        //                           <figure>
        //                             <img
        //                               onClick={() =>
        //                                 openLightBox(
        //                                   collection_images,
        //                                   idx,
        //                                 )
        //                               }
        //                               src={url}
        //                               alt={alt}
        //                               className="img-fluid"
        //                             />
        //                             <div>
        //                               <Tags tags={tags} />
        //                             </div>
        //                           </figure>
        //                         </SwiperSlide>
        //                       ),
        //                     )}
        //                 </Swiper>
        //                 <div className="mobile__title__slide">
        //                   <TitleSlide
        //                     have_nav={have_nav}
        //                     nav_title={nav_title}
        //                     navs={navs}
        //                   />
        //                 </div>
        //                 <hr
        //                   style={{
        //                     border: '1px solid white',
        //                     opacity: 1,
        //                   }}
        //                 ></hr>
        //               </SwiperSlide>
        //             </div>
        //           ),
        //         )}
        //       </Swiper>
        //       <Swiper
        //         onSwiper={setThumbsSwiper}
        //         slidesPerView={3}
        //         freeMode={true}
        //         watchSlidesProgress={true}
        //         className="mySwiper"
        //       >
        //         {menus.map(
        //           (
        //             { id, background_image, title, url, param },
        //             idx,
        //           ) => (
        //             <SwiperSlide>
        //               <Link
        //                 to={`${url}/${param}`}
        //                 ref={idx == 1 ? slideRef1 : null}
        //                 style={{
        //                   backgroundImage:
        //                     'url(' + background_image + ')',
        //                 }}
        //                 className="tumbslidermobileImage"
        //               ></Link>
        //               <Link
        //                 to={`${url}/${param}`}
        //                 className="tumbslidermobileTitle"
        //               >
        //                 {t(title)}
        //               </Link>
        //             </SwiperSlide>
        //           ),
        //         )}
        //       </Swiper>
        //     </>
        //   )}
        //   {activeTab === 'editorial' && (
        //     <>
        //       <Swiper
        //         className="mySwiper2"
        //         slidesPerView={'auto'}
        //         direction={'vertical'}
        //         thumbs={{ swiper: thumbsSwiperEditorial }}
        //         freeMode={true}
        //         mousewheel={true}
        //         ref={swiperRefVertical}
        //         onSlideChange={({ activeIndex }) =>
        //           onVerticalSlideChange(activeIndex)
        //         }
        //       >
        //         {editorials.map(
        //           (
        //             {
        //               title,
        //               title1,
        //               description,
        //               have_nav,
        //               nav_title,
        //               navs,
        //               collection_images,
        //               dataHash,
        //             },
        //             idx,
        //           ) => (
        //             <div key={`${idx}`}>
        //               <SwiperSlide
        //                 key={`${idx}`}
        //                 id={`mainSlide${idx}`}
        //                 className="swiper__slide1 swiper__lookbook"
        //               >
        //                 <div className="mobile__title__slide">
        //                   <TitleSlide
        //                     title={t(title)}
        //                     title1={t(title1)}
        //                     description={t(description)}
        //                   />
        //                 </div>
        //                 <Swiper
        //                   className="mySwiper3"
        //                   slidesPerView={1.2}
        //                   spaceBetween={20}
        //                 >
        //                   {collection_images.length > 0 &&
        //                     collection_images.map(
        //                       (
        //                         { id, url, caption, alt, tags },
        //                         idx,
        //                       ) => (
        //                         <SwiperSlide>
        //                           <figure>
        //                             <img
        //                               onClick={() =>
        //                                 openLightBox(
        //                                   collection_images,
        //                                   idx,
        //                                 )
        //                               }
        //                               src={url}
        //                               alt={alt}
        //                               className="img-fluid"
        //                             />
        //                             <div>
        //                               <Tags tags={tags} />
        //                             </div>
        //                           </figure>
        //                         </SwiperSlide>
        //                       ),
        //                     )}
        //                 </Swiper>
        //                 <div className="mobile__title__slide">
        //                   <TitleSlide
        //                     have_nav={have_nav}
        //                     nav_title={nav_title}
        //                     navs={navs}
        //                   />
        //                 </div>
        //                 <hr
        //                   style={{
        //                     border: '1px solid white',
        //                     opacity: 1,
        //                   }}
        //                 ></hr>
        //               </SwiperSlide>
        //             </div>
        //           ),
        //         )}
        //       </Swiper>
        //       <Swiper
        //         onSwiper={setThumbsSwiperEditorial}
        //         slidesPerView={3}
        //         freeMode={true}
        //         watchSlidesProgress={true}
        //         className="mySwiper"
        //       >
        //         {menus.map(
        //           (
        //             { id, background_image, title, url, param },
        //             idx,
        //           ) => (
        //             <SwiperSlide>
        //               <Link
        //                 to={`${url}/${param}`}
        //                 style={{
        //                   backgroundImage:
        //                     'url(' + background_image + ')',
        //                 }}
        //                 className="tumbslidermobileImage"
        //               ></Link>
        //               <Link
        //                 to={`${url}/${param}`}
        //                 className="tumbslidermobileTitle"
        //               >
        //                 {t(title)}
        //               </Link>
        //             </SwiperSlide>
        //           ),
        //         )}
        //       </Swiper>
        //     </>
        //   )}
        // </div>
        <div className={Styles.anniversaryPage}>
          <Tabs
            thumbSlide={thumbSlideMenu}
            toggle={toggle}
            activeTab={activeTab}
            collectionLabel={t('tabs.collection')}
            editorialLabel={t('tabs.campaign_adv')}
          />
          <div>
            {activeTab === 'lookBook' && (
              <>
                {lookBooks.map(
                  (
                    {
                      id,
                      title,
                      title1,
                      description,
                      have_nav,
                      nav_title,
                      navs,
                      collection_images,
                      dataHash,
                    },
                    idx,
                  ) => (
                    <div
                      onLoad={onLoadCollection}
                      key={`${idx}`}
                      id={`mainSlide${idx}`}
                    >
                      {customLookbookIds.map((customId) => {
                        return (
                          <div key={`${customId}`}>
                            {id === customId && (
                              <>
                                <TitleSlide
                                  title={t(title)}
                                  title1={t(title1)}
                                  description={t(description)}
                                  data-hash={dataHash}
                                />
                                <p id="showScroll"></p>
                                <Swiper
                                  {...swiperProps}
                                  mousewheel={false}
                                  onSlideChange={({ activeIndex }) =>
                                    onSlideChange(activeIndex)
                                  }
                                  ref={swiperRef}
                                >
                                  {collection_images.length > 0 &&
                                    collection_images.map(
                                      (
                                        {
                                          id,
                                          url,
                                          caption,
                                          alt,
                                          tags,
                                        },
                                        idx,
                                      ) => (
                                        <SwiperSlide
                                          data-hash={dataHash}
                                          className={`${Styles.deliveriesSlides}`}
                                          key={`${id}`}
                                        >
                                          <figure>
                                            <img
                                              onClick={() =>
                                                openLightBox(
                                                  collection_images,
                                                  idx,
                                                )
                                              }
                                              src={url}
                                              alt={alt}
                                              className="img-fluid"
                                            />
                                            <div>
                                              <Tags tags={tags} />
                                            </div>
                                          </figure>
                                        </SwiperSlide>
                                      ),
                                    )}
                                </Swiper>
                                <TitleSlide
                                  have_nav={have_nav}
                                  nav_title={nav_title}
                                  navs={navs}
                                />
                                <p id="showScroll"></p>
                                <hr
                                  style={{
                                    border: '1px solid white',
                                    opacity: 1,
                                  }}
                                ></hr>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ),
                )}
              </>
            )}
            {activeTab === 'editorial' && (
              <>
                {editorials.map(
                  (
                    {
                      id,
                      title,
                      title1,
                      description,
                      have_nav,
                      nav_title,
                      navs,
                      collection_images,
                      dataHash,
                    },
                    idx,
                  ) => (
                    <div
                      onLoad={onLoadCollection}
                      key={`${idx}`}
                      id={`mainSlide${idx}`}
                    >
                      {customEditorialsIds.map((customId) => {
                        return (
                          <div key={`${customId}`}>
                            {id === customId && (
                              <>
                                <TitleSlide
                                  title={t(title)}
                                  title1={t(title1)}
                                  description={t(description)}
                                />
                                <Swiper
                                  {...swiperProps}
                                  mousewheel={false}
                                  onActiveIndexChange={({
                                    activeIndex,
                                  }) => onSlideChange(activeIndex)}
                                  ref={swiperRef}
                                >
                                  {collection_images.length > 0 &&
                                    collection_images.map(
                                      (
                                        {
                                          id,
                                          url,
                                          caption,
                                          alt,
                                          tags,
                                        },
                                        idx,
                                      ) => (
                                        <SwiperSlide
                                          data-hash={dataHash}
                                          className={`${Styles.deliveriesSlides}`}
                                          key={`${id}`}
                                        >
                                          <figure>
                                            <img
                                              onClick={() =>
                                                openLightBox(
                                                  collection_images,
                                                  idx,
                                                )
                                              }
                                              src={url}
                                              alt={alt}
                                              className="img-fluid"
                                            />
                                            {/* <div>
                                              <Tags tags={tags} />
                                            </div> */}
                                          </figure>
                                        </SwiperSlide>
                                      ),
                                    )}
                                </Swiper>
                                <TitleSlide
                                  have_nav={have_nav}
                                  nav_title={nav_title}
                                  navs={navs}
                                />
                                <hr
                                  style={{
                                    border: '1px solid white',
                                    opacity: 1,
                                  }}
                                ></hr>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ),
                )}
              </>
            )}
          </div>
        </div>
      )}
      {!isM && (
        <>
          <div className={`mt-5 ${Styles.thumbSliderContainer}`}>
            <div className="row gx-0">
              <div className={`col-3 ${Styles.thumbSlideTitle}`}>
                <div className={Styles.text}>
                  <h5>{t('thumbSlide.discover_all')}</h5>
                </div>
                {/* Don't delete need to add in delivery-2 */}
                <div
                  className={Styles.thumbSlideLeftButton}
                  ref={prevRef}
                >
                  <AiOutlineLeft />
                </div>
              </div>
              <div className="col-8">
                <ThumbSlider
                  menus={menus}
                  setSlide={setSlide}
                  currentSlide={slide}
                  prevRef={prevRef}
                  nextRef={nextRef}
                  activeTab={activeTab}
                  slideRoute={params?.slide}
                  slideChange={slideChange}
                  setThumbSlideMenu={setThumbSlideMenu}
                />
              </div>
              {/* Don't delete need to add in delivery-2 */}
              <div
                className={`col-1 ${Styles.thumbSlideRightButton}`}
                ref={nextRef}
              >
                <AiOutlineRight />
              </div>
            </div>
          </div>
        </>
      )}
      {isM && (
        <>
          <div className={`mt-5 ${Styles.thumbSliderContainer}`}>
            <div className="row gx-0">
              <div className={`'col-12' ${Styles.thumbSlideTitle}`}>
                <div
                  className={Styles.thumbSlideLeftButton}
                  ref={prevRef}
                >
                  <AiOutlineLeft />
                </div>
              </div>
              <div className="col-12 thumbSliderMobile">
                <ThumbSliderMobile
                  menus={menus}
                  setSlide={setSlide}
                  currentSlide={slide}
                  prevRef={prevRef}
                  nextRef={nextRef}
                  activeTab={activeTab}
                  slideRoute={params}
                  slideChange={slideChange}
                  setThumbSlideMenu={setThumbSlideMenu}
                />
              </div>
              <div
                className={`col-1 ${Styles.thumbSlideRightButton}`}
                ref={nextRef}
              >
                <AiOutlineRight />
              </div>
            </div>
          </div>
        </>
      )}
      {isOpenLightBox && (
        <LightBox
          activeTab={activeTab}
          mainSrc={lightBoxImages[photoIndex]}
          photoIndex={photoIndex}
          collectionImages={lightBoxImages}
          onCloseRequest={(event) => closeLightBox(event)}
        />
      )}
    </>
  );
};

export default Anniversary;
