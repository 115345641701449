import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cache from 'i18next-localstorage-cache';

import en from './locales/en.json';
import it from './locales/it.json';
import de from './locales/de.json';
import es from './locales/es.json';
import fr from './locales/fr.json';
import jp from './locales/jp.json';
import cn from './locales/cn.json';
import pt from './locales/pt.json';
import ru from './locales/ru.json';

const resources = {
  en: {
    translation: en,
  },
  it: {
    translation: it,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  jp: {
    translation: jp,
  },
  cn: {
    translation: cn,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-in18next
  .use(Cache)
  .init({
    resources,
    debug: true,
    fallbackLng: 'en',

    keySeparator: '.',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    Cache: {
      enabled: false,
      prefix: 'translation_',
      expirationTime: Infinity,
      Version: {},
    },
  });

export default i18n;
