import React, { useContext, useState } from 'react';
import Styles from './menu.module.scss';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FaFacebook,
  FaInstagram,
  FaMapMarkerAlt,
} from 'react-icons/fa';
import Language from '../Language';
import DataContext from '../../context';
import { useIsMobile } from '../../utils/customHooks';
import { Link } from 'react-router-dom';

const Menu = ({ toggleMenu, language, setLanguage }) => {
  const { t } = useTranslation();
  const { menus } = useContext(DataContext);
  const isM = useIsMobile();
  return (
    <>
      <div className={Styles.menuContainer}>
        <div className={Styles.languageNav}>
          <Language
            toggleMenu={toggleMenu}
            language={language}
            setLanguage={setLanguage}
            isMenu={true}
          />
        </div>
        <button onClick={toggleMenu} className={Styles.closeBtn}>
          &times;
        </button>
        <div className={Styles.overlayContent}>
          <Language
            inlineLanguage={true}
            toggleMenu={toggleMenu}
            language={language}
            setLanguage={setLanguage}
          />
          {!isM && (
            <div className={Styles.menuItems}>
              {menus.map(
                ({ id, url, title, param, new_arrival }, idx) => (
                  <NavLink
                    key={id}
                    exact={true}
                    onClick={toggleMenu}
                    activeClassName={Styles.menuActive}
                    to={`${url}/${param}`}
                  >
                    {t(title)}

                    {new_arrival && <p>{t('new_arrivals')}</p>}
                  </NavLink>
                ),
              )}
            </div>
          )}
          {isM && (
            <div onClick={toggleMenu} className={Styles.menuItems}>
              {menus.map(
                ({ id, url, title, param, new_arrival }, idx) => (
                  <Link
                    key={id}
                    activeClassName={Styles.menuActive}
                    to={`${url}/${param}`}
                    onClick={() => {
                      if (idx == 0) {
                        document
                          .getElementById('mainSlide0')
                          .scrollIntoView();
                        document
                          .getElementById('tumbSlide0')
                          .classList.add(
                            'thumbSliderMobile_active__1n7h4',
                          );
                        document
                          .getElementById('tumbSlide1')
                          .classList.remove(
                            'thumbSliderMobile_active__1n7h4',
                          );
                        document
                          .getElementById('tumbSlide2')
                          .classList.remove(
                            'thumbSliderMobile_active__1n7h4',
                          );
                      } else if (idx == 1) {
                        document
                          .getElementById(`mainSlide1`)
                          .scrollIntoView();
                        document
                          .getElementById('tumbSlide1')
                          .classList.add(
                            'thumbSliderMobile_active__1n7h4',
                          );
                        document
                          .getElementById('tumbSlide0')
                          .classList.remove(
                            'thumbSliderMobile_active__1n7h4',
                          );
                        document
                          .getElementById('tumbSlide2')
                          .classList.remove(
                            'thumbSliderMobile_active__1n7h4',
                          );
                      } else if (idx == 2) {
                        document
                          .getElementById(`mainSlide2`)
                          .scrollIntoView();
                        document
                          .getElementById('tumbSlide2')
                          .classList.add(
                            'thumbSliderMobile_active__1n7h4',
                          );
                        document
                          .getElementById('tumbSlide0')
                          .classList.remove(
                            'thumbSliderMobile_active__1n7h4',
                          );
                        document
                          .getElementById('tumbSlide1')
                          .classList.remove(
                            'thumbSliderMobile_active__1n7h4',
                          );
                      }
                    }}
                  >
                    {t(title)}

                    {new_arrival && <p>{t('new_arrivals')}</p>}
                  </Link>
                ),
              )}
            </div>
          )}
          <a
            className="d-flex align-items-center"
            href={`https://www.armaniexchange.com/experience/en/store-locator/`}
            target="_blank"
          >
            <FaMapMarkerAlt /> <span>STORE LOCATOR</span>
          </a>
        </div>
        <div className={Styles.socialLinks}>
          <a
            href="https://www.facebook.com/armaniexchange"
            target="_blank"
          >
            <FaFacebook />
          </a>
          <a
            href="https://www.instagram.com/armaniexchange/"
            target="_blank"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
    </>
  );
};

export default Menu;
